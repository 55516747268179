import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { StoreService } from 'src/app/services/store/store.service';
import { UserService } from 'src/app/services/user/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-store-manager',
  templateUrl: './store-manager.component.html',
  styleUrls: ['./store-manager.component.scss']
})
export class StoreManagerComponent implements OnInit {

  myFormControl = new FormControl();

  orgId: any;
  constructor(private cookiesService: CookiesService, private router: Router, private storeService: StoreService, private userService: UserService,
    private matSnackBar: MatSnackBar) {
    this.orgId = this.cookiesService.getCookie('orgId');
    this.getStoreListByOrgId(this.orgId);
    this.getUserListByMasterRole();
  }

  ngOnInit() {
  }

  stores: any = [];
  // GET StoreList by passing orgId
  getStoreListByOrgId(orgId) {
    this.storeService.getStoreListByParam(orgId).subscribe(res => {
      this.stores = res.responseData;
      console.log('stores: ', this.stores);
    }),
      error => {
        console.log(error);
      }
  }

  users: any;
  masterRoleCode: string = 'STORE_MANAGER'
  getUserListByMasterRole() {
    this.userService.getUserListByMasterRole(this.masterRoleCode, this.orgId)
      .subscribe(
        res => {
          this.users = res.responseData;
          console.log('users: ', this.users);
        }
      );
  }

  selectedStoreId: any;
  onSelectStore(event) {
    console.log(event);
    this.selectedStoreId = event.value.id;
  }

  selectedUserId;
  onSelectUser(event) {
    console.log('', event);
    this.selectedUserId = event.value.id;
    this.disabled = false;
  }

  disabled: boolean = true;
  onClick() {
    let data = {
      "userId": [this.selectedUserId],
      "storeId": +this.selectedStoreId,
      "orgId": +this.orgId
    }
    this.addStoreManager(data);
  }
  addStoreManager(data) {
    this.storeService.addSalesAreaManager(data)
      .subscribe(
        res => {
          if (res.statusInfo.statusCode === 200) {
            this.matSnackBar.open('Store Manager role is assigned successfully', 'SUCCESS', { duration: 3000 });
            this.router.navigate(['/dashboard/store']);
          }
        }, error => {
          console.log('error message: ', error);
        }
      );
  }

}
