import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { RAIN } from '../../app-constants';
import { environment } from '../../../environments/environment';
import { ErrorService } from '../error/error.service';
import { catchError, retry } from 'rxjs/operators';
import { CookiesService } from '../cookies/cookies.service';

@Injectable({
  providedIn: 'root'
})
export class AreaService {

  ENV: any = environment;
  constructor(private httpClient: HttpClient, private errorHandler: ErrorService, private cookiesService: CookiesService) { }

  // Store-area mapping related URLs

  private GET_SALES_AREA_BY_PARAM_URL = RAIN.BASE + RAIN.MASTER + RAIN.SALES_AREA + RAIN.APIS.GET_ALL_SALES_AREA;
  private GET_STORE_BY_PARAM_URL = RAIN.BASE + RAIN.MASTER + RAIN.STORE + RAIN.APIS.GET_ALL_STORE;
  private GET_STORE_BY_SALES_AREA_CODE_AND_ORG_ID_URL = RAIN.BASE + RAIN.MASTER + RAIN.STORE + RAIN.APIS.GET_ALL;
  private MAP_STORE_TO_SALES_AREA_URL = RAIN.BASE + RAIN.MASTER + RAIN.SALES_AREA + RAIN.APIS.MAP_STORE_TO_AREA;
  private UNMAP_STORE_FROM_AREA_URL = RAIN.BASE + RAIN.MASTER + RAIN.SALES_AREA + RAIN.APIS.DELETE_STORE_TO_SALES_AREA;
  private ADD_SALES_AREA_URL = RAIN.BASE + RAIN.MASTER + RAIN.SALES_AREA + RAIN.APIS.UPDATE;
  private DELETE_SALES_AREA_URL = RAIN.BASE + RAIN.MASTER + RAIN.SALES_AREA + RAIN.APIS.DELETE_SALES_AREA;
  private ADD_SALES_AREA_MANAGER = RAIN.BASE + RAIN.MASTER + RAIN.SALES_AREA + RAIN.APIS.ADD_SALES_AREA_MANAGER;
  private UPDATE_SALES_AREA = RAIN.BASE + RAIN.MASTER + RAIN.SALES_AREA + '';
  private GET_SALES_AREA_BY_SALE_AREA_ID = RAIN.BASE + RAIN.MASTER + RAIN.SALES_AREA + RAIN.APIS.GET_SALES_AREA;

  // GET Sales Area List by orgId
  getSalesAreaListByParam(orgId) {
    let params = new HttpParams();
    params = params.append('isActive', 'true');
    params = params.append('orgId', orgId);
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: params
    }
    return this.httpClient.get<any>(this.GET_SALES_AREA_BY_PARAM_URL, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }
  // GET Store List by AreaCode and OrgId
  getStoreByAreaIdAndOrgId(salesAreaId, orgId) {
    let params = new HttpParams();
    params = params.append('orgId', orgId);
    if (salesAreaId != null) {
      params = params.append('salesAreaId', salesAreaId);
    }
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: params
    }
    return this.httpClient.get<any>(this.GET_STORE_BY_SALES_AREA_CODE_AND_ORG_ID_URL, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }

  // GET Store List by OrgId
  getStoreListByParam(orgId) {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: new HttpParams().set('orgId', orgId)
    }
    console.log('orgId checking::', orgId);
    return this.httpClient.get<any>(this.GET_STORE_BY_PARAM_URL, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }

  // POST call - mapping Store-country
  mapStoreToArea(salesAreaId, stores, orgId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      })
    };
    return this.httpClient.post<any>(this.MAP_STORE_TO_SALES_AREA_URL, { salesAreaId, stores, orgId }, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }
  // POST call - unmap store from area
  unmapStoreFromArea(salesAreaId, stores, orgId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      })
    };
    return this.httpClient.post<any>(this.UNMAP_STORE_FROM_AREA_URL, { salesAreaId, stores, orgId }, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }

  // POST call - add new Sales Area
  addSalesArea(data) {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    };
    return this.httpClient.post<any>(this.ADD_SALES_AREA_URL, data, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }

  // POST call - delete Sales Area
  deleteSalesArea(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      })
    };
    return this.httpClient.post<any>(this.DELETE_SALES_AREA_URL, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  addSalesAreaManager(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      })
    };
    return this.httpClient.post<any>(this.ADD_SALES_AREA_MANAGER, data, httpOptions);
  }

  updateSalesArea(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      })
    };
    return this.httpClient.post<any>(this.UPDATE_SALES_AREA, data, httpOptions);
  }

  getSalesAreaByAreaId(id, orgId) {
    let params = new HttpParams();
    params = params.append('id', id);
    params = params.append('orgId', orgId);
    const httpOptions =
    {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      }),
      params: params
    };
    return this.httpClient.get<any>(this.GET_SALES_AREA_BY_SALE_AREA_ID, httpOptions);
  }

}
