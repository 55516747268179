import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { RAIN } from '../../app-constants';
import { environment } from '../../../environments/environment';
import { CookiesService } from '../cookies/cookies.service';
@Injectable({
  providedIn: 'root'
})
export class SpaceService {

  ENV: any = environment;
  constructor(private httpClient: HttpClient, private cookiesService: CookiesService) { }

  private ADD_SPACE_URL = RAIN.BASE + RAIN.MASTER + RAIN.STORE + RAIN.APIS.CREATE_SPACE;
  private GET_SPACE_BY_PARAM_URL = RAIN.BASE + RAIN.MASTER + RAIN.STORE + RAIN.APIS.GET_ALL_SPACE;
  private GET_ALL_SPACE_BY_PARAM_URL = RAIN.BASE + RAIN.MASTER + RAIN.STORE + RAIN.APIS.GET_ALL_SPACE_BY_ORG;
  private GET_SPACE_BY_SPACE_ID = RAIN.BASE + RAIN.MASTER + RAIN.STORE + RAIN.APIS.GET_ALL_SPACE;
  private DELETE_SPACE = RAIN.BASE + RAIN.MASTER + RAIN.STORE + RAIN.APIS.DELETE_SPACE;
  private UPDATE_SPACE = RAIN.BASE + RAIN.MASTER + RAIN.STORE + RAIN.APIS + '';

  // POST call - add new space
  addSpace(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      })
    };
    return this.httpClient.post<any>(this.ADD_SPACE_URL, data, httpOptions);

  }
  getAllSpaceListByOrgId(orgId) {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        },
      ),
      params: new HttpParams().set('orgId', orgId)
    }
    return this.httpClient.get<any>(this.GET_ALL_SPACE_BY_PARAM_URL, httpOptions)
      .pipe(map(
        res => { return res; },
        error => {
          console.log(error);
        }));
  }

  // GET Space list by orgId
  getSpaceListByOrgId(orgId) {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        },
      ),
      params: new HttpParams().set('orgId', orgId)
    }
    return this.httpClient.get<any>(this.GET_SPACE_BY_PARAM_URL, httpOptions)
      .pipe(map(
        res => { return res; },
        error => {
          console.log(error);
        }));
  }


  // POST call - delete Space
  deleteSpace(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      })
    };
    return this.httpClient.post<any>(this.DELETE_SPACE, data, httpOptions);
  }

  updateSpace(data) {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    };
    return this.httpClient.post<any>(this.UPDATE_SPACE, data, httpOptions);
  }

  getSpaceBySpaceId(id, orgId) {
    let params = new HttpParams();
    params = params.append('orgId', orgId);
    params = params.append('id', id);
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: params
    };
    return this.httpClient.get<any>(this.GET_SPACE_BY_SPACE_ID, httpOptions);
  }

}
