import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { AreaService } from 'src/app/services/area/area.service';
import { UserService } from 'src/app/services/user/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sales-area-manager',
  templateUrl: './sales-area-manager.component.html',
  styleUrls: ['./sales-area-manager.component.scss']
})
export class SalesAreaManagerComponent implements OnInit {

  orgId: any;
  constructor(private cookiesService: CookiesService, private router: Router, private areaService: AreaService, private userService: UserService, private matSnackBar: MatSnackBar) {
    this.orgId = this.cookiesService.getCookie('orgId');
    this.getSalesAreaListByOrgId(this.orgId);
    this.getUserListByMasterRole();
  }
  myFormControl = new FormControl();
  ngOnInit() {
  }

  areas: any = [];
  // GET SalesArea List by passing orgId 
  getSalesAreaListByOrgId(orgId) {
    this.areaService.getSalesAreaListByParam(orgId)
      .subscribe(
        res => {
          this.areas = res.responseData;
          console.log('get Sales Area List by OrgId', res);
        },
        err => {
          console.log(err);
        }
      );
  }

  selectedSalesArea: string;
  onSelectSalesArea(event) {
    console.log(event);
    this.selectedSalesArea = event.value.id;
  }

  selectedUserId;
  onSelectUser(event) {
    console.log('', event);
    this.disabled = false;
    this.selectedUserId = event.value.id;
  }

  users: any;
  masterRoleCode: string = 'SALESAREA_MANAGER';
  getUserListByMasterRole() {
    this.userService.getUserListByMasterRole(this.masterRoleCode, this.orgId)
      .subscribe(
        res => {
          this.users = res.responseData;
          console.log('users: ', this.users);
        }
      );
  }

  addSalesAreaManager(data) {
    this.areaService.addSalesAreaManager(data)
      .subscribe(
        res => {
          if (res.statusInfo.statusCode === 200) {
            this.matSnackBar.open('Area Manager role assigned successfully', 'SUCCESS', { duration: 3000 });
            this.router.navigate(['dashboard/salesArea']);
          }
        },
        error => { console.log('error message: '); }
      )
  }

  disabled: boolean = true;
  onClick() {
    let data = {
      "userId": [this.selectedUserId],
      "salesAreaId": +this.selectedSalesArea,
      "orgId": +this.orgId
    }
    this.addSalesAreaManager(data);
  }
}
