import { CountryService } from './../../../../../services/country/country.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { Router } from '@angular/router';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dashboard-list',
  templateUrl: './dashboard-list.component.html',
  styleUrls: ['./dashboard-list.component.scss']
})
export class DashboardListComponent implements OnInit {

  searchText;
  orgId: any = [];
  constructor(private router: Router, private snackBar: MatSnackBar,
    private cookiesService: CookiesService, private dashboardService: DashboardService) {
    this.orgId = this.cookiesService.getCookie('orgId');
    this.getDashboardList();
  }

  ngOnInit() {
  }

  routerFlag: boolean = false;
  hideFlag: boolean = true;

  addDashboardFlag() {
    this.hideFlag = false;
    this.routerFlag = true;
  }

  dashboards: any = [];
  getDashboardList() {
    this.dashboardService.getDashboards(this.orgId).subscribe(
      res => {
        this.dashboards = res;
        console.log('Dashboard List: ', res);
      },
      error => {
        console.log('Error message: ', error);
      }
    );
  }

  goToDashboard(dashboard) {
    console.log('clicked dash ', dashboard);
    this.cookiesService.putCookie('dashboardId', dashboard.id);
    this.router.navigate(['dashboard/dashboardDetail']);
    this.router.navigateByUrl('dashboard/dashboardDetail', { state: { id: dashboard.id, name: dashboard.name } });
  }

  onClickDelete(visualization) {
    this.deleteDashboard(visualization.id);
  }

  deleteDashboard(id) {
    this.dashboardService.deleteDashboardById(id)
      .subscribe(
        res => {
          if (res.statusInfo.statusCode === 200) {
            this.snackBar.open('Dashboard has been deleted.', 'SUCCESS', { duration: 2000 });
            this.getDashboardList();
          }
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }

}
