import { filter, map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET, RoutesRecognized, RouterEvent } from '@angular/router';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { stringify } from 'querystring';
import { OrganizationService } from 'src/app/services/organization/organization.service';
import { pipe } from 'rxjs';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {

  orgData: any = [];
  logoUrl: any;
  orgId: any;
  flag = false;
  flagDash = false;
  // breadcrumbFlag = false;
  currentUrl: string;
  constructor(private router: Router, private cookiesService: CookiesService, private orgService: OrganizationService) {
    this.orgId = this.cookiesService.getCookie('orgId');
    // this.logoUrl = this.cookiesService.getCookie('bLogo');
    // this.logoUrl = localStorage.getItem('bLogo');
    this.getOrgDetailsByOrgId();
  }

  getOrgDetailsByOrgId() {
    this.orgService.getOrgDetailsByOrgId(this.orgId)
      .subscribe(res => {
        console.log('get org details: ', res)
        this.logoUrl = res.responseData[0].logoUrl;
      });
  }

  ngOnInit() {
    this.logoUrl = localStorage.getItem('bLogo');
  }

  logOut() {
    localStorage.clear();
    this.cookiesService.clear();
    this.router.navigate(['/login']);
  }

}
