import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-create',
  templateUrl: './dashboard-create.component.html',
  styleUrls: ['./dashboard-create.component.scss']
})
export class DashboardCreateComponent implements OnInit {

  orgId;
  constructor(private formBuilder: FormBuilder,
    private cookiesService: CookiesService, private dashboardService: DashboardService, private _snackBar: MatSnackBar,
    private router: Router) {
    this.orgId = this.cookiesService.getCookie('orgId');
  }


  dashboardGroup: FormGroup;

  ngOnInit() {
    this.dashboardGroup = this.formBuilder.group({
      dashboardName: ['', Validators.required],
      dashboardCode: ['', Validators.required],
      description: ['', Validators.required],
      placement: ['', Validators.required],
      isActive: [''],
    });
  }

  get f() { return this.dashboardGroup.controls; }
  submitted: boolean = false;
  onSubmit(f) {
    this.submitted = true;
    if (this.dashboardGroup.valid) {
      this.addNewDashboard()
    }
    return;
  }

  addNewDashboard() {
    // addNewDashboard(name, description, code, isActive, placement) {
    let data = {
      name: this.dashboardGroup.value.dashboardName,
      code: this.dashboardGroup.value.dashboardCode,
      isActive: this.dashboardGroup.value.isActive,
      placement: this.dashboardGroup.value.placement,
      description: this.dashboardGroup.value.description,
      orgId: +this.cookiesService.getCookie('orgId'),
    };
    // const data = {
    //   name: name.value,
    //   code: code.value,
    //   isActive: +isActive.value,
    //   placement: placement.value,
    //   description: description.value,
    //   orgId: +this.orgId
    // };
    this.dashboardService.addNewDashboard(data)
      .subscribe(res => {
        if (res.statusInfo.statusCode === 200) {
          this._snackBar.open('New dashboard added successfully', 'SUCCESS', { duration: 2000 });
          this.router.navigate(['/dashboard/dashboardList']);
        }
      },
        error => {
          console.log('error message: ', error);
        }
      );
  }

}
