import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user/user.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-visualization-details',
  templateUrl: './visualization-details.component.html',
  styleUrls: ['./visualization-details.component.scss']
})
export class VisualizationDetailsComponent implements OnInit {

  constructor(private router: Router, private cookiesService: CookiesService, private dashboardService: DashboardService,
    private _snackBar: MatSnackBar, private userService: UserService) {
    this.visualizationId = this.cookiesService.getCookie('visualizationId');
    this.orgId = this.cookiesService.getCookie('orgId');
    this.getChartByVisualizationId();
    this.getVisualizationById(this.visualizationId);
    this.getRolesByOrgId(this.orgId);
    // this.getRolesByVisualizationId();
    this.getUnmappedChartToVisualization();
  }
  orgId: any;
  visualizationId;

  ngOnInit() {
  }

  flag: boolean = true;
  goToVisualization() {
    // if (this.flag === true) {
    this.router.navigate(['dashboard/visualizationList']);
    // }
    // else {
    // this.router.navigate(['dashboard/dashboardList']);
    // }
  }

  goToDashboard() {
    this.router.navigate(['dashboard/dashboardDetail']);
  }

  roleForm: FormGroup;
  onCheckBoxSubmit() {
    console.log("role form data: ", this.roleForm.value);
  }

  onClickUpdateDetail(name, description, visual_rank) {
    const data =
    {
      name: name,
      id: +this.cookiesService.getCookie('visualizationId'),
      description: description,
      isActive: 1,
      visual_rank: +visual_rank
    };
    console.log('data-->', data);
    this.updateVisualizationDetail(data);
  }

  disabled: boolean = true;
  hide() {
    this.disabled = false;
  }

  checkedItems = [];
  checkedIdList = [];
  updateRoles(formDataRef) {
    // console.log('--->>>>', formDataRef);
    var roles = [];
    this.checkedItems = formDataRef.value;
    this.orgId = this.cookiesService.getCookie('orgId');
    this.checkedIdList = [];
    // tslint:disable-next-line: forin
    for (let i in this.roles) {
      if (this.checkedItems[this.roles[i].id] === true) {
        this.checkedIdList.push({ id: this.roles[i].id }); // +++
      }
      i = i + 1;
    }
    this.mapRoleToVisualization(this.visualizationId, this.checkedIdList, this.orgId);
  }

  onClickMapChartToVisualization(chart) {
    console.log('selected chart for map: ', chart);
    this.mapChartToVisualization(this.visualizationId, chart.id);
  }

  onClickUnmapChartToVisualization(chart) {
    console.log('selected chart for unmap: ', chart);
    this.unmapChartToVisualization(this.visualizationId, chart.id);
  }

  visualization: any;
  getVisualizationById(data) {
    this.dashboardService.getVisualizationById(data)
      .subscribe(res => {
        this.visualization = res.responseData;
      },
        error => {
          console.log('error message: ', error);
        }
      );
  }

  roles: any = []
  rolesWithFlag = [];
  getRolesByOrgId(orgId) {
    this.roles = [];
    this.rolesWithFlag = [];
    this.userService.getRoleListByOrgId(orgId).subscribe(
      res => {
        this.roles = res;
        for (let i in res) {
          this.rolesWithFlag.push({ res, 'isSelected': false });
        }
        this.getRolesByVisualizationId();
      },
      error => {
        console.log(error);
      }
    );
  }

  assignedRoles = [];
  assignedRolesData = [];
  tempRolesWithFlag: any;
  getRolesByVisualizationId() {
    this.assignedRoles = [];
    this.dashboardService.getRoleByVisualizationId(this.visualizationId, this.orgId)
      .subscribe(
        res => {
          this.tempRolesWithFlag = this.rolesWithFlag;
          // tslint:disable-next-line: forin
          for (let j in res.responseData) {
            // tslint:disable-next-line: forin
            for (let k in this.tempRolesWithFlag) {
              if (res.responseData[j].id === this.tempRolesWithFlag[k].res[k].id) {
                this.tempRolesWithFlag[k].isSelected = true;
              }
              k = k + 1;
            }
            j = j + 1;
          }
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }

  mappedCharts: any = [];
  getChartByVisualizationId() {
    this.dashboardService.getChartByVisualizationId(this.visualizationId, this.orgId)
      .subscribe(
        res => {
          this.mappedCharts = res.responseData;
          console.log('mapped chart list---->', this.mappedCharts);
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }

  unmappedCharts: any = [];
  getUnmappedChartToVisualization() {
    this.dashboardService.getUnmappedChartToVisualization(this.visualizationId, this.orgId)
      .subscribe(
        res => {
          this.unmappedCharts = res.responseData;
          console.log('unmapped chart list: ', this.unmappedCharts);
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }

  mapChartToVisualization(id, chart_id) {
    this.dashboardService.mapChartToVisualization(id, chart_id, this.orgId)
      .subscribe(
        res => {
          if (res.statusInfo.statusCode === 200) {
            this._snackBar.open('mapped successfully', 'success', {
              duration: 2000,
            });
            this.getChartByVisualizationId();
            this.getUnmappedChartToVisualization();
          }
        },
        error => {
          console.log('error message: ', error);
        }
      )
  }

  unmapChartToVisualization(id, chart_id) {
    this.dashboardService.unmapChartToVisualization(id, chart_id, this.orgId)
      .subscribe(
        res => {
          if (res.statusInfo.statusCode === 200) {
            this._snackBar.open('unmapped successfully!', 'success', {
              duration: 2000,
            });
          }
          this.getChartByVisualizationId();
          this.getUnmappedChartToVisualization();
        },
        error => {
          console.log('error message ', error);
        }
      )
  }

  mapRoleToVisualization(visualizationId, roles, orgId) {
    orgId = this.cookiesService.getCookie("orgId");
    this.dashboardService.mapRoleToVisualization(visualizationId, roles, orgId)
      .subscribe(
        res => {
          if (res.statusInfo.statusCode === 200) {
            this._snackBar.open('mapped operation successful', 'SUCCESS', {
              duration: 2000,
            });
          }
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }

  updateVisualizationDetail(data) {
    this.dashboardService.updateVisualization(data)
      .subscribe(
        res => {
          if (res.statusInfo.statusCode === 200) {
            this._snackBar.open('visualization information is updated', 'SUCCESS', { duration: 2000 });
          }
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }

}
