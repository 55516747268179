import { Component, OnInit, Input } from '@angular/core';
import { StoreService } from 'src/app/services/store/store.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-store-create',
  templateUrl: './store-create.component.html',
  styleUrls: ['./store-create.component.scss']
})
export class StoreCreateComponent implements OnInit {

  createStoreForm: FormGroup;
  public organizationId;
  submitted: boolean = false; 
  successMessage: string;
  dataInsertBool = true;

  constructor(private storeService: StoreService, private formBuilder: FormBuilder,
    private cookiesService: CookiesService, private router: Router, private matSnackBar: MatSnackBar) { }

  ngOnInit() {
    this.createStoreForm = this.formBuilder.group({
      storeName: [],
      storeCode: ['', Validators.required],
      displayName: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      street: ['', Validators.required],
      pincode: ['', Validators.required],
      state: ['', Validators.required],
      storePhone: ['', Validators.compose([Validators.minLength(10), Validators.maxLength(10), Validators.required])],
      storeEmail: ['', Validators.required],
      storeSize: ['', Validators.required],
      storeOpenTime: ['', Validators.required],
      storeCloseTime: ['', Validators.required],
      isActive: ['']
      // orgId: ['', Validators.required],
    });
  }

  get f() { return this.createStoreForm.controls; }

  onSubmit(f) {
    this.submitted = true;
    if (this.createStoreForm.valid) {
      this.onStoreAdded()
    }
    return;
  }

  onStoreAdded() {
    console.log('createStoreForm: ', this.createStoreForm)
    let StoreSpace = {
      store:
      {
        storeName: this.createStoreForm.value.storeName,
        storeCode: this.createStoreForm.value.storeCode,
        storeDisplayName: this.createStoreForm.value.displayName,
        address:
        {
          address: this.createStoreForm.value.address,
          city: this.createStoreForm.value.city,
          street: this.createStoreForm.value.street,
          pincode: +this.createStoreForm.value.pincode,
          state: this.createStoreForm.value.state
        },
        storePhone: this.createStoreForm.value.storePhone,
        storeEmail: this.createStoreForm.value.storeEmail,
        storeSize: this.createStoreForm.value.storeSize,
        storeOpenTime: this.createStoreForm.value.storeOpenTime,
        storeCloseTime: this.createStoreForm.value.storeCloseTime,
        orgId: +this.cookiesService.getCookie('orgId'),
        isActive: +this.createStoreForm.value.isActive
      }
    };
    console.log('send store data ', StoreSpace);
    this.storeService.addStore(StoreSpace)
      .subscribe(
        res => {
          console.log('add store response: ', res);
          if (res.statusInfo.statusCode === 200) {
            this.dataInsertBool = false;
            this.successMessage = 'store details added successfully';
            this.matSnackBar.open('Store added successfully', 'SUCCESS', { duration: 2000 });
            this.router.navigate(['/dashboard/store']);
          }
        },
        error => { console.log('error message : ', error); });
  }

}
