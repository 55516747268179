import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


export const routes: Routes = [
  // { path: '', loadChildren: './core/core.module#CoreModule' },
  // { path: '', loadChildren: './modules/main.module#MainModule'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
