import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';

@Injectable({
  providedIn: 'root'
})
export class AwsSdkService {

  constructor() { }


  public uploadfile(file) {

    const bucket = new S3(
      {
        accessKeyId: 'AKIAUAXLRTC3MSI4KMNA',
        secretAccessKey: '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',
        region: 'ap-south-1'
      }
    );

    const params = {
      Bucket: 'rain-files',
      Key: file.name,
      Body: file
    };

    bucket.upload(params, function (err, data) {
      if (err) {
        console.log('There was an error uploading your file: ', err);
        return false;
      }

      console.log('Successfully uploaded file.', data);
      localStorage.setItem('brandLogoUrl', data.Location);
      return true;
    });
  }
}
