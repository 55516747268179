import { Component, OnInit } from '@angular/core';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { CountryService } from 'src/app/services/country/country.service';
import { FormControl } from '@angular/forms';
import { UserService } from 'src/app/services/user/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-country-manager',
  templateUrl: './country-manager.component.html',
  styleUrls: ['./country-manager.component.scss']
})
export class CountryManagerComponent implements OnInit {

  orgId: any;
  constructor(private cookiesService: CookiesService, private router: Router, private countryService: CountryService, private userService: UserService, private matSnackBar: MatSnackBar) {
    this.orgId = this.cookiesService.getCookie('orgId');
    this.getCountryListByOrgId(this.orgId);
    this.getUserListByMasterRole();
  }

  disabled: boolean = true;
  myFormControl = new FormControl();
  ngOnInit() {

  }

  countries: any = [];
  // GET countryList by passing orgId
  getCountryListByOrgId(orgId) {
    this.countryService.getCountryListByParam(orgId)
      .subscribe(
        res => {
          this.countries = res.responseData;
          console.log('country list', res);
        },
        err => {
          console.log(err);
        }
      );
  }

  users: any;
  countryManagerCode: string = "COUNTRY_MANAGER";
  getUserListByMasterRole() {
    this.userService.getUserListByMasterRole(this.countryManagerCode, this.orgId)
      .subscribe(
        res => {
          this.users = res.responseData;
          console.log('users: ', this.users);
        }
      );
  }

  onClick() {
    let data = {
      "userId": [this.selectedUserId],
      "countryCode": this.selectedCountryCode,
      "orgId": this.orgId
    }
    this.addCountryManager(data);
  }

  addCountryManager(data) {
    this.countryService.addCountryManager(data)
      .subscribe(res => {
        if (res.statusInfo.statusCode === 200) {
          this.matSnackBar.open('Country Manager Role assigned successfully', 'SUCCESS', { duration: 3000 });
          this.router.navigate(['/dashboard/country']);
        }
      },
        error => {
          console.log('error message: ', error);
        })
  }

  selectedCountryCode: string;
  onSelectCountry(event) {
    console.log('', event.value.code);
    this.selectedCountryCode = event.value.code;
  }

  selectedUserId;
  onSelectUser(event) {
    console.log('', event);
    this.disabled = false;
    this.selectedUserId = event.value.id;
  }
} 
