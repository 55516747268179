import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { RAIN } from '../../app-constants';
import { environment } from '../../../environments/environment';
import { CookiesService } from '../cookies/cookies.service';
@Injectable({
  providedIn: 'root'
})
export class RoleService {

  ENV: any = environment;
  constructor(private httpClient: HttpClient, private cookiesService: CookiesService) { }

  ADD_ROLE_URL = RAIN.BASE + RAIN.USER + RAIN.ADD + RAIN.APIS.ROLE;
  DELETE_ROLE_URL = RAIN.BASE + RAIN.USER + RAIN.APIS.DELETE_ROLE;
  // DELETE_ROLE_URL = 'http://localhost:8081/' + RAIN.USER + RAIN.APIS.DELETE_ROLE;
  GET_ROLE_LIST_URL = RAIN.BASE + RAIN.USER + RAIN.APIS.ROLES;
  GET_ROLE_LIST_URL_temp = RAIN.BASE + RAIN.USER + RAIN.APIS.ROLES;

  GET_MAPPED_ACTION_LIST = RAIN.BASE + RAIN.USER + RAIN.APIS.GET_MAP_ACTION_LIST; // need to be updated
  GET_UNMAPPED_ACTION_LIST = RAIN.BASE + RAIN.USER + RAIN.APIS.GET_UNMAP_ACTION_LIST;
  MAP_ACTION_TO_ROLE = RAIN.BASE + RAIN.USER + RAIN.APIS.MAP_ACTION_TO_ROLE;
  UNMAP_ACTION_TO_ROLE = RAIN.BASE + RAIN.USER + RAIN.APIS.UNMAP_ACTION_TO_ROLE;

  // MASTER ROLE 
  MAP_USER_MASTER_ROLE_COUNTRY_ORG = RAIN.BASE + RAIN.USER + RAIN.APIS.MAP_USER_MASTER_ROLE_COUNTRY_ORG;
  MAP_USER_MASTER_ROLE_SALES_AREA_ORG = RAIN.BASE + RAIN.MASTER + RAIN.APIS.MAP_USER_MASTER_ROLE_SALES_AREA_ORG; // check base url
  MAP_USER_MASTER_ROLE_STORE_ORG = RAIN.BASE + RAIN.MASTER + RAIN.APIS.MAP_USER_MASTER_ROLE_STORE_ORG;

  private GET_ORG_DOMAIN_ROLE_URL = RAIN.BASE + RAIN.USER + RAIN.APIS.DOMAIN_ROLE;

  // POST request for adding new role
  addRole(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      })
    };
    // createdBy = +createdBy;
    return this.httpClient.post<any>(this.ADD_ROLE_URL, data, httpOptions)
      .pipe(map(res => { console.log('res of add role api ', res); return res; }, error => { console.log(error); }));
  }

  deleteRole(data) {
    const httpOptions =
    {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      })
    }
    return this.httpClient.post<any>(this.DELETE_ROLE_URL, data, httpOptions)
      .pipe(map(res => { console.log('res of delete role api ', res); return res; }, error => { console.log(error); }));
  }

  // GET call - getting role list which are unmapped 
  getRoleListByOrgId(orgId) {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    };
    this.GET_ROLE_LIST_URL = this.GET_ROLE_LIST_URL_temp;
    this.GET_ROLE_LIST_URL = this.GET_ROLE_LIST_URL + '/' + orgId;
    return this.httpClient.get<any>(this.GET_ROLE_LIST_URL, httpOptions)
      .pipe(map(res => { return res; }, error => { console.log(error); }));
  }

  getMappedActionList(id) {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: new HttpParams().set('role_id', id)
    };

    return this.httpClient.get<any>(this.GET_MAPPED_ACTION_LIST, httpOptions);
  }

  getUnmappedActionList(id) {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: new HttpParams().set('role_id', id)
    };
    return this.httpClient.get<any>(this.GET_UNMAPPED_ACTION_LIST, httpOptions);
  }

  getActionList() {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    };
    return this.httpClient.get<any>(this.GET_UNMAPPED_ACTION_LIST, httpOptions);
  }

  mapActionToRole(data) {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    };
    return this.httpClient.post<any>(this.MAP_ACTION_TO_ROLE, data, httpOptions);
  }

  unmapActionToRole(data) {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    };
    return this.httpClient.post<any>(this.UNMAP_ACTION_TO_ROLE, data, httpOptions);
  }

  mapUserMasterRoleCountryOrg(data) {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    };
    return this.httpClient.post<any>(this.MAP_USER_MASTER_ROLE_COUNTRY_ORG, data, httpOptions);
  }

  mapUserMasterRoleStoreOrg(data) {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    };
    return this.httpClient.post<any>(this.MAP_USER_MASTER_ROLE_STORE_ORG, data, httpOptions);
  }
  mapUserMasterRoleSalesAreaOrg(data) {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    };
    return this.httpClient.post<any>(this.MAP_USER_MASTER_ROLE_SALES_AREA_ORG, data, httpOptions);
  }

  getRolesByOrgDomain(data) {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: new HttpParams().set('orgDomain', data)
    };
    return this.httpClient.get<any>(this.GET_ORG_DOMAIN_ROLE_URL, httpOptions);
  }

}