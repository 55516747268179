import 'src/polyfills';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './components/layout/layout.component';
import { RouterModule } from '@angular/router';
import { dashboardRoutes } from './dashboard.routes';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BreadcrumbComponent } from '../../shared/breadcrumb/breadcrumb.component';
import { StoreCreateComponent } from './components/storesTab/store-create/store-create.component';
import { StoreMappingComponent } from './components/storesTab/store-mapping/store-mapping.component';
import { SpacesCreateComponent } from './components/spacesTab/spaces-create/spaces-create.component';
import { PulseCreateComponent } from './components/pulseTab/pulse-create/pulse-create.component';
import { UserCreateComponent } from './components/userTab/user-create/user-create.component';
import { AddRoleComponent } from './components/roleTab/add-role/add-role.component';
import { OrgInfoComponent } from './components/settingsTab/org-info/org-info.component';
import { AreaCreateComponent } from './components/area/area-create/area-create.component';
import { AreaMappingComponent } from './components/area/area-mapping/area-mapping.component';
import { CountryComponent } from './components/countryTab/country/country.component';
import { CountryCreateComponent } from './components/countryTab/country-create/country-create.component';
import { CountryMappingComponent } from './components/countryTab/country-mapping/country-mapping.component';
import { TopNavComponent } from 'src/app/shared/top-nav/top-nav.component';
import { OrganizationComponent } from './components/organization/organization.component';
import { AuthGuard } from 'src/app/shared/helpers/guards/auth.guard';
import { ReactiveFormsModule } from '@angular/forms';
import { MyMaterialModule } from 'src/app/material-module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MatNativeDateModule } from '@angular/material/core';
import { UserListComponent } from './components/userTab/user-list/user-list.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SharedModule } from 'src/app/shared/shared.module';
import { PulseComponent } from './components/pulseTab/pulse/pulse.component';
import { PulseListComponent } from './components/pulseTab/pulse-list/pulse-list.component';
import { AreasComponent } from './components/area/areas/areas.component';
import { StoresComponent } from './components/storesTab/stores/stores.component';
import { SpaceComponent } from './components/spacesTab/space/space.component';
import { PulseConfigMapComponent } from './components/pulseTab/pulse-config-map/pulse-config-map.component';
import { DashboardListComponent } from './components/dashboardTab/dashboard-list/dashboard-list.component';
import { DashboardCreateComponent } from './components/dashboardTab/dashboard-create/dashboard-create.component';
import { VisualizationCreateComponent } from './components/visualizationTab/visualization-create/visualization-create.component';
import { VisualizationListComponent } from './components/visualizationTab/visualization-list/visualization-list.component';
import { BreadcrumbsModule } from './components/ng6-breadcrumbs';
import { VisualizationDetailsComponent } from './components/visualizationTab/visualization-details/visualization-details.component';
import { DashboardDetailsComponent } from './components/dashboardTab/dashboard-details/dashboard-details.component';
import { ChartComponent } from './components/chart/chart.component';
import { ChartDetailComponent } from './components/chart/chart-detail/chart-detail.component';
import { RoleListComponent } from './components/roleTab/role-list/role-list.component';
import { AddChartComponent } from './components/chart/add-chart/add-chart.component';
import { MapRoleActionComponent } from './components/roleTab/map-role-action/map-role-action.component';
import { AreaDetailComponent } from './components/area/area-detail/area-detail.component';
import { UserListRoleComponent } from './components/roleTab/user-list-role/user-list-role.component';
import { CountryDetailComponent } from './components/countryTab/country-detail/country-detail.component';
import { StoreDetailComponent } from './components/storesTab/store-detail/store-detail.component';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { CountryManagerComponent } from './components/countryTab/country-manager/country-manager.component';
import { SalesAreaManagerComponent } from './components/area/sales-area-manager/sales-area-manager.component';
import { StoreManagerComponent } from './components/storesTab/store-manager/store-manager.component';
import { MappedPulseListComponent } from './components/pulseTab/mapped-pulse-list/mapped-pulse-list.component';
import { SpaceDetailComponent } from './components/spacesTab/space-detail/space-detail.component';
@NgModule({
  declarations: [LayoutComponent, OrganizationComponent,
    TopNavComponent, AreaCreateComponent,
    AreaMappingComponent, CountryComponent, CountryCreateComponent,
    CountryMappingComponent, BreadcrumbComponent, StoreCreateComponent,
    StoreMappingComponent, SpacesCreateComponent, PulseCreateComponent,
    UserCreateComponent, AddRoleComponent,
    OrgInfoComponent, UserListComponent, PulseComponent, PulseListComponent,
    AreasComponent, StoresComponent, SpaceComponent, PulseConfigMapComponent,
    DashboardListComponent, DashboardCreateComponent, VisualizationCreateComponent,
    VisualizationListComponent,
    VisualizationDetailsComponent,
    DashboardDetailsComponent,
    ChartComponent,
    ChartDetailComponent,
    RoleListComponent,
    AddChartComponent,
    MapRoleActionComponent,
    CountryDetailComponent,
    AreaDetailComponent,
    UserListRoleComponent,
    StoreDetailComponent,
    CountryManagerComponent,
    SalesAreaManagerComponent,
    StoreManagerComponent,
    MappedPulseListComponent,
    SpaceDetailComponent
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    MyMaterialModule,
    ClipboardModule,
    MatFormFieldModule,
    Ng2SearchPipeModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    BreadcrumbsModule,
    RouterModule.forChild(dashboardRoutes),
    SharedModule,
    NgxQRCodeModule
  ],
  providers: [AuthGuard],
  exports: [CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule]
})
export class DashboardModule { }
// platformBrowserDynamic().bootstrapModule(DashboardModule);
