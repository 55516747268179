import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { SpaceService } from 'src/app/services/space/space.service';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AwsSdkService } from 'src/app/services/aws-sdk/aws-sdk.service';

@Component({
  selector: 'app-spaces-create',
  templateUrl: './spaces-create.component.html',
  styleUrls: ['./spaces-create.component.scss']
})
export class SpacesCreateComponent implements OnInit {

  createSpaceForm: FormGroup;
  successMessage: string;
  dataInsertBool = true;
  shareOrgIdWithMap: string;
  submitted: boolean = false;

  constructor(private spaceService: SpaceService, private cookiesService: CookiesService
    , private router: Router, private formBuilder: FormBuilder, private awsService: AwsSdkService) { }

  ngOnInit() {

    this.createSpaceForm = this.formBuilder.group({
      spaceName: ['', Validators.required],
      spaceCode: ['', Validators.required],
      displayName: ['', Validators.required],
      description: ['', Validators.required],
      // url: [''],
      isActive: ['false'],
    });
    this.shareOrgIdWithMap = this.cookiesService.getCookie('orgId');
  }

  get f() { return this.createSpaceForm.controls; }

  onSubmit(f) {
    this.submitted = true;
    if (this.createSpaceForm.valid) {
      this.onSpaceAdded();
    }
    return;
  }

  onSpaceAdded() {
    let data = {
      name: this.createSpaceForm.value.spaceName,
      code: this.createSpaceForm.value.spaceCode,
      displayName: this.createSpaceForm.value.displayName,
      description: this.createSpaceForm.value.description,
      // url: this.createSpaceForm.value.url,
      url: this.brandLogoUrl,
      createdBy: +this.cookiesService.getCookie('createdBy'),
      isActive: this.createSpaceForm.value.isActive,
      orgId: +this.cookiesService.getCookie('orgId'),
    };
    console.log('space data: ', data);
    this.spaceService.addSpace(data)
      .subscribe(
        res => {
          if (res.statusInfo.statusCode === 200) {
            this.dataInsertBool = false;
            this.successMessage = 'space created successfully';
            this.router.navigate(['/dashboard/space']);
          }
        },
        error => { console.log('error message: ', error); });
  }

  logoUploadedUrl: any;
  public selectedFiles: FileList;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  @ViewChild('url', { static: false }) url: any;
  public onFileChange(event) {
    this.selectedFiles = event.target.files;
    this.upload();
  }
  fileName: any;
  brandLogoUrl = 'https://rain-files.s3.ap-south-1.amazonaws.com/';
  public upload() {
    const file = this.selectedFiles.item(0);
    this.fileName = file.name;
    this.awsService.uploadfile(file);
    this.brandLogoUrl = this.brandLogoUrl + file.name;
  }
}
