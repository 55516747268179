import { Component, OnInit } from '@angular/core';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { ChartService } from 'src/app/services/chart/chart.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-chart-detail',
  templateUrl: './chart-detail.component.html',
  styleUrls: ['./chart-detail.component.scss']
})
export class ChartDetailComponent implements OnInit {

  orgId;
  chartId;
  disabled: boolean = true;

  constructor(private cookiesService: CookiesService, private chartService: ChartService, private _snackBar: MatSnackBar) {
    this.orgId = this.cookiesService.getCookie('orgId');
    this.chartId = this.cookiesService.getCookie('chartId');
    this.getChartDetail();
  }

  onEditClick() {
    this.disabled = false;
  }

  ngOnInit() {
  }

  chart: any = [];
  getChartDetail() {
    this.chartService.getChartById(this.chartId, this.orgId)
      .subscribe(
        res => {
          this.chart = res.responseData;
          console.log('chart details: ', res);
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }

  updateChartDetail(name, code, description, serviceApi, chartType, filter) {
    const data =
    {
      name: name,
      code: code,
      description: description,
      isDetail: 0,
      chartType: chartType,
      filter: filter,
      isActive: 1,
      id: +this.cookiesService.getCookie('chartId'),
      serviceApi: +serviceApi
      // orgId: +this.orgId
    };
    console.log('data==> ', data);
    this.updateChartInformation(data);
  }
  
  onSlideClick(user, slideVal) {
    let data = user;
    data['isActive'] = +!slideVal.checked;
    // this.updateUserDetail(data);
  }
  updateChartInformation(data) {
    this.chartService.updateChartDetails(data)
      .subscribe(
        res => {
          console.log('res of update api: ', res);
          if (res.statusInfo.statusCode === 200) {
            this._snackBar.open('chart information is updated successfully!', 'SUCCESS', { duration: 2000 });
          }
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }
}
