import { environment } from 'src/environments/environment';

export const RAIN = {

    BASE: environment.APIEndpoint,
    BASE1: environment.APIEndpoint1,
    PULSE_FEEDBACK_DOMAIN: environment.PulseFeedbackWebURL,

    MASTER: 'master/',
    USER: 'user/',
    ORG: 'org/',
    META: 'meta/',
    DASHBOARD: 'dashboard/',
    COUNTRY: 'country/',
    SALES_AREA: 'salesArea/',
    STORE: 'store/',
    SPACE: 'space/',
    ROLE: 'role/',
    PULSE: 'pulse/',
    MAPPING: 'mapping/',
    ADD: 'add/',
    RATING: 'rating/',

    /*  Apis are made as constants and are loaded to the services,
        import the constant file into the services and then call it like -
        For API calls - RAIN.BASE_URL + RAIN.USER + RAIN.APIS.LOGIN
        For messages - RAIN.MESSAGES.USERNAME_REQUIRED
        For constants - RAIN.CONSTANTS.CONSTANTS_NAME
    */

    APIS: {

        'LOGIN': 'login',
        'TOKEN_VALIDATE': 'tokenValidate',
        'GET_ALL_ORG': 'getOrganization',
        'ADD_ORG': 'addOrganization',
        'ORG_DOMAIN': 'orgDomain',
        'ORG': 'org',
        'GET_COUNTRY_LIST': 'getCountryList',
        'MAP_SALES_AREA_TO_COUNTRY': 'mapSalesAreaToCountry',
        'GET_SALES_AREA': 'getSalesAreas',
        'GET_ALL_UNMAPPED_STORE': 'getAll',
        'CREATE': 'create',
        'GET_ALL_SALES_AREA': 'getAllSalesAreas',
        'GET_ALL_SPACE': 'getAllSpace',
        'GET_ALL_SPACE_BY_ORG': 'getAllSpaceByOrg',
        'GET_ALL_STORE': 'getAllStore',
        'GET_ALL': 'getAll',
        'MAP_STORE_TO_AREA': 'mapStoresToSalesArea',
        'MAP_SPACE_TO_STORE': 'mapStoreToSpace',
        'UNMAP_SALES_AREA_TO_COUNTRY': 'deleteSalesAreaToCountry',
        'CREATE_OR_UPDATE_COUNTRY': 'createOrUpdateCountry',
        'DELETE_STORE_TO_SALES_AREA': 'deleteStoreToSalesArea',
        'DELETE_SPACE_TO_STORE': 'deleteStoreToSpace',
        'CREATE_SPACE': 'createSpace',
        'MAPPING': 'mapping',  // mapping role
        'CREATE_OR_UPDATE': 'createOrUpdate',
        'GET_ALL_USER': 'getAllUser',
        'ROLES': 'roles',
        'DELETE': 'delete',
        'GET_PULSE_RATING_CONFIG': 'getPulseRatingConfig',
        'ADD_OR_UPDATE_PULSE_CONFIG': 'addOrUpdatePulseConfig',
        'ROLE': 'role',
        'GET_ALL_PULSE_RATING_CONFIG': 'getAllPulseRatingConfig',
        'UPDATE_CONFIG_TO_STORE_SPACE': 'updateConfigToStoreSpace',
        'DELETE_COUNTRY': 'deleteCountry',
        'DELETE_SALES_AREA': 'deleteSalesArea',
        'DELETE_STORE': 'deleteStore',
        'DELETE_SPACE': 'deleteSpace',
        'DELETE_PULSE_CONFIG': 'deletePulseConfig',
        'DELETE_USER': 'deleteUser',
        'DELETE_ROLE': 'deleteRole',
        'UPDATE_ORG_DETAIL': 'updateOrg',
        'GET_ALL_VISUALIZATIONS': 'getAllVisualizations',
        'ADD_CHART': 'addChart',
        'GET_ALL_CHART': 'getAllChart',
        'GET_CHART': 'getChart',
        'GET_SERVICE_API': 'getServiceApi',
        'GET_ALL_SERVICE_API': 'getAllServiceApi',
        'ADD_NEW_VISUALIZATION': 'addNewVisualization',
        'GET_VISUALIZATION': 'getVisualization',
        'GET_ROLE_BY_VISUALIZATION': 'getRoleByVisualization',
        'GET_CHART_BY_VISUALIZATION': 'getChartByVisualization',
        'GET_UNMAPPED_CHART_TO_VISUALIZATION': 'getUnmappedChartToVisualization',
        'MAP_CHART_TO_VISUALIZATION': 'mapChartToVisualization',
        'UNMAP_CHART_TO_VISUALIZATION': 'unmapChartToVisualization',
        'MAP_ROLE_TO_VISUALIZATION': 'mapRoleToVisualization',
        'MAP_ROLE_TO_DASHBOARD': 'mapRoleToDashboard',
        'MAP_VISUALIZATION_TO_DASHBOARD': 'mapVisualizationToDashboard',
        'UNMAP_VISUALIZATION_FROM_DASHBOARD': 'unmapVisualizationFromDashboard',
        'ADD_NEW_DASHBOARD': 'addNewDashboard',
        'GET_ALL_DASHBOARD': 'getAllDashboard',
        'GET_MAPPED_VISUALIZATION': 'getMappedVisualization',
        'GET_UNMAPPED_VISUALIZATION': 'getUnmappedVisualization',
        'GET_DASHBOARD_DETAIL': 'getDashboardDetail',
        'GET_ROLE_BY_DASHBOARD': 'getRoleByDashboard',
        'GET_ACTIVE_CHART': 'getActiveChart',
        'DELETE_DASHBOARD': 'deleteDashboard',
        'DELETE_VISUALIZATION': 'deleteVisualization',
        'DELETE_CHART': 'deleteChart',
        'UPDATE_DASHBOARD': 'updateDashboard',
        'UPDATE_VISUALIZATION': 'updateVisualization',
        'UPDATE_CHART': 'updateChart',
        'MAP_ACTION_TO_ROLE': 'mapActionToRole',
        'UNMAP_ACTION_TO_ROLE': 'unmapActionToRole',
        'GET_MAP_ACTION_LIST': 'getMapActionToRole',
        'GET_UNMAP_ACTION_LIST': 'getUnmapActionToRole',
        'GET_USERS_BY_MASTER_ROLE': 'getUsersByMasterRole',
        'MAP_USER_MASTER_ROLE_COUNTRY_ORG': 'mapUserMasterRoleCountryOrg',
        'MAP_USER_MASTER_ROLE_SALES_AREA_ORG': 'mapUserMasterRoleSalesAreaOrg',
        'MAP_USER_MASTER_ROLE_STORE_ORG': 'mapUserMasterRoleStoreOrg',
        'GET_MASTER_ROLE_BY_ORG_DOMAIN': 'getMasterRoleByOrgDomain',
        'GET_STORE_SPACE_BY_PULSE': 'getStoreSpaceByPulse',
        'DOMAIN_ROLE': 'domainRole',
        'ADD_COUNTRY_MANAGER': 'addCountryManager',
        'ADD_SALES_AREA_MANAGER': 'addSalesAreaManager',
        'ADD_STORE_MANAGER': 'addStoreManager',
        'GET_CHART_TYPE_BY_APIS': 'getChartTypeByApi',
        'GET_ALL_PULSE_MAP': 'getAllPulseMap',
        'UPDATE_QR_CODE': 'updateQRCode',
        'ENABLE_AGE_GROUP': 'enableAgeGroup',
        'DELETE_PULSE_CONFIG_TO_STORE_SPACE': 'deletePulseConfigToStoreSpace',
        'GET': 'get',
        'UPDATE': 'update'
    },

    CONSTANTS: {

    },


    ROUTERLINKS: {

        'ROOT': '',
        'LOGIN': 'login',
        'DASHBOARD': 'dashboard',
        'COUNTRY': 'country',
        'CREATE_COUNTRY': 'createCountry',
        'MAP_COUNTRY': 'mapCountry',
        'COUNTRY_DETAIL': 'countryDetail',
        'AREA_DETAIL': 'areaDetail',
        'CREATE_AREA': 'createArea',
        'MAP_AREA': 'mapArea',
        'CREATE_STORES': 'createStores',
        'MAP_STORE': 'mapStore',
        'CREATE_SPACES': 'createSpaces',
        'CREATE_PULSE': 'createPulse',
        'CREATE_USER': 'createUser',
        'ADD_ROLE': 'addRole',
        'USERS_BY_ROLE': 'usersByRole',
        'USER_LIST': 'userList',
        'MAP_USER_ROLE': 'mapUserRole',
        'MAP_ROLE_ACTION': 'mapRoleAction',
        'ORG_INFO': 'orgInfo',
        'SALES_AREA': 'salesArea',
        'STORE': 'store',
        'STORE_DETAIL': 'storeDetail',
        'SPACE': 'space',
        'PULSE_CONFIG_LIST': 'pulseConfigList',
        'PULSE_CONFIG_MAP': 'pulseConfigMap',
        'MAPPED_PULSE_LIST': 'mappedPulseList',
        'DASHBOARD_LIST': 'dashboardList',
        'DASHBOARD_CREATE': 'createDashboard',
        'DASHBOARD_DETAIL': 'dashboardDetail',
        'VISUALIZATION_LIST': 'visualizationList',
        'VISUALIZATION_CREATE': 'createVisualization',
        'VISUALIZATION_DETAIL': 'visualizationDetail',
        'CHART': 'chart',
        'CHART_INFORMATION': 'chartInformation',
        'ROLE_LIST': 'roleList',
        'ADD_CHART': 'addChart',
        'COUNTRY_MANAGER': 'countryManager',
        'SALES_AREA_MANAGER': 'salesAreaManager',
        'STORE_MANAGER': 'storeManager',
        'SPACE_DETAIL': 'spaceDetail'
    },


    MESSAGES: {
        'NO_MATCH_FOUND': 'No Match Found...',
        'ORGANIZATION_ADD_SUCCESS': 'Organization added successfully',
        'ORGANIZATION_UPDATE_SUCCESS': 'Organization updated successfully',
        'ORGANIZATION_DELETE_SUCCESS': 'Organization deleted successfully',
        'PULSE_CONFIG_ADD_SUCCESS': 'Pulse configuration added successfully'
    }
};