import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET, RoutesRecognized, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
// import { BreadcrumbsService } from 'ng6-breadcrumbs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  // currentUrl: string = this.router.url
  currentUrl: string
  superAdmin: any;
  constructor(public router: Router, private _cookieService: CookiesService, private activatedroute: ActivatedRoute) {
    this.superAdmin = localStorage.getItem('superAdmin');
    console.log('superAdmin ', this.superAdmin);
    router.events.pipe(
      filter(e => e instanceof RouterEvent)
    ).subscribe(e => {
      this.currentUrl = e['url']
    });
  }

  checkRole() {
    if (this._cookieService.getCookie('role') === 'ROLE_ADMIN') {
      return true;
    }
    return false;
  }

  // data;
  ngOnInit() {
    // this.activatedroute.data.subscribe(data => {
    //   this.data = data;
    //   console.log('---> ', data);
    // })
  }

  clickBreadcrumb() {
    console.log('clicked breadcrumb');
  }

  goToSuperAdminDash() {
    // if (this._cookieService.getCookie('superAdminOrgId')) {
    // this._cookieService.putCookie('bUrl', this._cookieService.getCookie('superAdminOrgId'));
    this._cookieService.putCookie('orgId', this._cookieService.getCookie('superAdminOrgId'));
    // }
    this.router.navigate(['/org']);
  }
}
