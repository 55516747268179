import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { OrganizationService } from 'src/app/services/organization/organization.service';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AwsSdkService } from 'src/app/services/aws-sdk/aws-sdk.service';

@Component({
  selector: 'app-org-info',
  templateUrl: './org-info.component.html',
  styleUrls: ['./org-info.component.scss']
})
export class OrgInfoComponent implements OnInit {

  orgId;
  successMessage: string;
  dataInsertBool = true;
  disabled = true;
  constructor(private awsService: AwsSdkService, private cookiesService: CookiesService,
    private orgService: OrganizationService, private _snackBar: MatSnackBar) {
    this.orgId = this.cookiesService.getCookie('orgId');
    this.getOrgDetailsByOrgId();
  }

  ngOnInit() {
  }

  onEditClick() {
    this.disabled = false;
  }

  orgData: any = [];
  getOrgDetailsByOrgId() {
    this.orgService.getOrgDetailsByOrgId(this.orgId)
      .subscribe(res => {
        this.orgData = res.responseData[0];
        this.orgLogoUrl = this.orgData.logoUrl;
        this.brandLogoUrl = this.orgData.logoUrl;
      },
        error => {
          console.log('error ', error);
        }
      );
  }

  name: string;
  orgLogoUrl: string;
  title: string;
  color: string;
  createdBy: string;
  domain: string;
  orgCode: string;
  orgPin: string;
  // Add organization service call for adding data
  onSubmitOrgDetails(name, orgLogoUrl, title, color, domain, orgCode, orgPin) {
    this.name = name.value;
    this.orgLogoUrl = this.brandLogoUrl;
    this.title = title.value;
    this.color = color.value;
    // this.createdBy = createdBy.value;
    this.domain = domain.value;
    this.orgCode = orgCode.value;
    this.orgPin = orgPin.value;
    (this.orgService.updateOrgDetail(this.name, this.orgLogoUrl, this.title, this.color, this.domain, this.orgId,
      this.orgCode, this.orgPin).subscribe(
        response => {
          console.log('res: ', response);
          if (response.statusInfo.statusCode === 200) {
            this._snackBar.open('org detail is updated successfully!', 'SUCCESS', {
              duration: 2000,
            });
          }
        },
        error => {
          console.log('error message: ', error);
        }));
  }

  logoUploadedUrl: any;
  public selectedFiles: FileList;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  @ViewChild('url', { static: false }) url: any;
  public onFileChange(event) {
    this.selectedFiles = event.target.files;
    console.log(this.selectedFiles);
    this.upload();
  }
  fileName: any;
  brandLogoUrl;
  public upload() {
    const file = this.selectedFiles.item(0);
    this.fileName = file.name;
    this.brandLogoUrl = 'https://rain-files.s3.ap-south-1.amazonaws.com/';
    this.awsService.uploadfile(file);
    this.brandLogoUrl = this.brandLogoUrl + file.name;
    this.orgData.logoUrl = this.brandLogoUrl;
  }

}
