import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store/store.service';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss']
})
export class StoresComponent implements OnInit {

  orgId;
  constructor(private storeService: StoreService, private cookiesService: CookiesService, private router: Router,
    private _snackBar: MatSnackBar) {
    this.orgId = this.cookiesService.getCookie('orgId');
    this.getStoreListByOrgId(this.orgId);
  }

  onClickEdit(store) {
    this.cookiesService.putCookie('storeId', store.id);
    this.router.navigate(['/dashboard/storeDetail']);
  }

  ngOnInit() {
  }

  stores: any = [];
  address;
  // GET StoreList by passing orgId
  getStoreListByOrgId(orgId) {
    this.storeService.getStoreListByParam(orgId).subscribe(res => {
      console.log('Store list ', res);
      this.stores = res;
    }),
      error => {
        console.log(error);
      }
  }
  routerFlag = false;
  hideFlag: boolean = true;
  mapStoreFlag() {
    this.hideFlag = false;
    this.routerFlag = true;
  }

  addStoreFlag() {
    this.hideFlag = false;
    this.routerFlag = true;
  }

  onDelete(store) {
    console.log('delete area data', store);
    store['orgId'] = +this.orgId;
    console.log('...', store);
    this.deleteStore(store);
  }

  deleteStore(store) {
    this.storeService.deleteStore(store).subscribe(
      res => {
        console.log('delete api call response ', res);
        if (res.statusInfo.statusCode === 200) {
          this._snackBar.open('area is deleted successfully!', 'SUCCESS', {
            duration: 2000,
          });
          this.getStoreListByOrgId(this.orgId);
        }
      },
      err => {
        console.log(err);
      }
    );
  }
}
