import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user/user.service';
import { FormGroup, NgForm } from '@angular/forms';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';

@Component({
  selector: 'app-dashboard-details',
  templateUrl: './dashboard-details.component.html',
  styleUrls: ['./dashboard-details.component.scss']
})
export class DashboardDetailsComponent implements OnInit {

  searchText;
  orgId: any;
  dashboardId;
  constructor(private router: Router, private cookiesService: CookiesService,
    private _snackBard: MatSnackBar, private dashboardService: DashboardService,
    private userService: UserService) {
    console.log('....state.... ', this.router.getCurrentNavigation().extras.state);
    this.orgId = +this.cookiesService.getCookie('orgId');
    this.dashboardId = +this.cookiesService.getCookie('dashboardId');
    // this.getRolesByDashboardId();
    this.getDashboardDetail();
    this.getRolesByOrgId(this.orgId);
    this.getMappedVisualization();
    this.getUnmappedVisualization();
  }

  ngOnInit() {

  }

  goToVisualization(visualization) {
    this.cookiesService.putCookie('visualizationId', visualization.id);
    this.router.navigate(['dashboard/visualizationDetail']);
  }

  goToDashboard() {
    this.router.navigate(['dashboard/dashboardList']);
  }

  onClickMapVisualizationToDashboard(visualization) {
    console.log('selected visualization for map', visualization, ' dash ID: ', this.dashboardId);
    this.mapVisualizationToDashboard(this.dashboardId, visualization.id);
  }

  onClickUnmapVisualizationFromDashboard(visualization) {
    console.log('selected visualization for unmap', visualization, ' dash ID: ', this.dashboardId);
    this.unmapVisualizationFromDashboard(this.dashboardId, visualization.id);
  }

  disabled: boolean = true;
  hide() {
    this.disabled = false;
  }

  onClickUpdateDetail(name, code, description, placement) {
    const data =
    {
      id: +this.cookiesService.getCookie('dashboardId'),
      name: name,
      code: code,
      isActive: 1,
      description: description,
      placement: placement
    };
    console.log('update details: ', data);
    this.updateDashboardDetail(data);
  }

  roles: any = [];
  rolesWithFlag: any = [];
  getRolesByOrgId(orgId) {
    this.roles = [];
    this.rolesWithFlag = [];
    this.userService.getRoleListByOrgId(orgId)
      .subscribe(
        res => {
          this.roles = res;
          // tslint:disable-next-line: forin
          for (let i in res) {
            this.rolesWithFlag.push({ res, 'isSelected': false });
          }
          console.log('with flag ', this.rolesWithFlag);
          this.getRolesByDashboardId();
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }

  assignedRoles = [];
  assignedRolesData = [];
  tempRolesWithFlag: any;
  getRolesByDashboardId() {
    this.assignedRoles = [];
    this.dashboardService.getRoleByDashboardId(this.dashboardId, this.orgId)
      .subscribe(
        res => {
          this.tempRolesWithFlag = this.rolesWithFlag;
          // tslint:disable-next-line: forin
          for (let j in res.responseData) {
            // tslint:disable-next-line: forin
            for (let k in this.tempRolesWithFlag) {
              if (res.responseData[j].id === this.tempRolesWithFlag[k].res[k].id) {
                this.tempRolesWithFlag[k].isSelected = true;
              }
              k = k + 1;
            }
            j = j + 1;
          }
          console.log('after matching', this.tempRolesWithFlag);
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }
  // tslint:disable-next-line: member-ordering
  roleForm: FormGroup;
  onCheckBoxSubmit() {
    console.log("role form data:", this.roleForm.value);
  }

  mappedVisualizations: any = [];
  getMappedVisualization() {
    this.dashboardService.getMappedVisualizationByDashboardId(this.dashboardId, this.orgId)
      .subscribe(res => {
        this.mappedVisualizations = res.responseData;
      },
        error => {
          console.log('error message: ', error);
        }
      );
  }

  unmappedVisualizations: any = [];
  getUnmappedVisualization() {
    this.dashboardService.getUnmappedVisualizationByDashboardId(this.dashboardId, this.orgId)
      .subscribe(res => {
        this.unmappedVisualizations = res.responseData;
      }),
      // tslint:disable-next-line: no-unused-expression
      error => {
        console.log('error message: ', error);
      };
  }

  dashboard: any = [];
  getDashboardDetail() {
    this.dashboardService.getDashboardByID(this.dashboardId)
      .subscribe(res => {
        this.dashboard = res.responseData;
      },
        error => {
          console.log('error message: ', error);
        }
      );
  }

  mapVisualizationToDashboard(id, visualization_id) {
    console.log(id, visualization_id);
    this.dashboardService.mapVisualizationToDashboard(this.dashboardId, visualization_id, this.orgId)
      .subscribe(
        res => {
          if (res.statusInfo.statusCode === 200) {
            this._snackBard.open('mapping operation is successfull', 'SUCCESS', { duration: 2000 });
            this.getMappedVisualization();
            this.getUnmappedVisualization();
          }
        },
        error => {
          console.log();
        }
      )

  }

  unmapVisualizationFromDashboard(id, visualization_id) {
    this.dashboardService.unmapVisualizationToDashboard(id, visualization_id, +this.orgId)
      .subscribe(
        res => {
          if (res.statusInfo.statusCode === 200) {
            this._snackBard.open('unamapping operation is successfull', 'SUCCESS', { duration: 2000 });
            this.getMappedVisualization();
            this.getUnmappedVisualization();
          }
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }

  checkedItems = [];
  checkedIdList = [];
  updateRoles(formDataRef: NgForm) {
    var roles = [];
    this.checkedItems = formDataRef.value;
    console.log('checked values: ', this.checkedItems);
    this.orgId = this.cookiesService.getCookie('orgId');
    this.checkedIdList = [];
    // tslint:disable-next-line: forin
    for (let i in this.roles) {
      if (this.checkedItems[this.roles[i].id] === true) {
        this.checkedIdList.push({ id: this.roles[i].id }); // +++
      }
      i = i + 1;
    }
    this.assignRoleToDashboard(this.dashboardId, this.checkedIdList, this.orgId);
  }

  assignRoleToDashboard(dashboard_id, roles, orgId) {
    // roles.push()
    this.dashboardService.mapRoleToDashboard(dashboard_id, roles, +orgId)
      .subscribe(
        res => {
          console.log('role list is updated for the dashboard', res);
          if (res.statusInfo.statusCode === 200) {
            this._snackBard.open('role list is updated', 'SUCCESS', {
              duration: 2000,
            });
          }
        },
        error => {
          console.log('error');
        }
      );
  }

  updateDashboardDetail(data) {
    this.dashboardService.updateDashboardDetails(data)
      .subscribe(
        res => {
          console.log('update api res', res);
          if (res.statusInfo.statusCode === 200) {
            this._snackBard.open('dashboard details updated', 'SUCCESS', { duration: 2000 });
          }
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }

}
