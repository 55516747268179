import { Component, OnInit } from '@angular/core';
import { AreaService } from 'src/app/services/area/area.service';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-areas',
  templateUrl: './areas.component.html',
  styleUrls: ['./areas.component.scss']
})
export class AreasComponent implements OnInit {

  orgId;
  constructor(private areaService: AreaService, private cookiesService: CookiesService,
    private _snackBar: MatSnackBar, private router: Router) {
    this.orgId = this.cookiesService.getCookie('orgId');
    this.getSalesAreaListByOrgId(this.orgId);
  }

  ngOnInit() {
  }

  salesAreas: any = []
  // GET SalesArea List by passing orgId
  getSalesAreaListByOrgId(orgId) {
    this.areaService.getSalesAreaListByParam(orgId)
      .subscribe(
        res => {
          this.salesAreas = res;
          console.log('get Sales Area List by OrgId', this.salesAreas);
        },
        err => {
          console.log(err);
        }
      );
  }

  routerFlag = false;
  hideFlag: boolean = true;
  mapAreaFlag() {
    this.hideFlag = false;
    this.routerFlag = true;
  }

  addAreaFlag() {
    this.hideFlag = false;
    this.routerFlag = true;
  }

  onDelete(area) {
    console.log('delete area data', area);
    area['orgId'] = +this.orgId;
    console.log('...', area);
    this.deleteArea(area);
  }

  deleteArea(area) {
    this.areaService.deleteSalesArea(area).subscribe(
      res => {
        console.log('delete api call response ', res);
        if (res.statusInfo.statusCode === 200) {
          this._snackBar.open('area is deleted successfully!', 'success', {
            duration: 2000,
          });
          this.getSalesAreaListByOrgId(this.orgId);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  onClickEdit(area) {
    this.cookiesService.putCookie('areaId', area.id);
    this.router.navigate(['/dashboard/areaDetail']);
  }

}
