import { Routes } from '@angular/router';
import { LayoutComponent } from '../dashboard/components/layout/layout.component';
import { AuthGuard } from 'src/app/shared/helpers/guards/auth.guard';
import { OrganizationComponent } from './components/organization/organization.component';
import { CountryComponent } from './components/countryTab/country/country.component';
import { CountryCreateComponent } from './components/countryTab/country-create/country-create.component';
import { CountryMappingComponent } from './components/countryTab/country-mapping/country-mapping.component';
import { AreaCreateComponent } from './components/area/area-create/area-create.component';
import { AreaMappingComponent } from './components/area/area-mapping/area-mapping.component';
import { StoreCreateComponent } from './components/storesTab/store-create/store-create.component';
import { StoreMappingComponent } from './components/storesTab/store-mapping/store-mapping.component';
import { SpacesCreateComponent } from './components/spacesTab/spaces-create/spaces-create.component';
import { PulseCreateComponent } from './components/pulseTab/pulse-create/pulse-create.component';
import { UserCreateComponent } from './components/userTab/user-create/user-create.component';
import { AddRoleComponent } from './components/roleTab/add-role/add-role.component';
import { OrgInfoComponent } from './components/settingsTab/org-info/org-info.component';
import { UserListComponent } from './components/userTab/user-list/user-list.component';
import { RAIN } from '../../app-constants'
import { AreasComponent } from './components/area/areas/areas.component';
import { StoresComponent } from './components/storesTab/stores/stores.component';
import { SpaceComponent } from './components/spacesTab/space/space.component';
import { PulseListComponent } from './components/pulseTab/pulse-list/pulse-list.component';
import { PulseConfigMapComponent } from './components/pulseTab/pulse-config-map/pulse-config-map.component';
import { DashboardCreateComponent } from './components/dashboardTab/dashboard-create/dashboard-create.component';
import { DashboardListComponent } from './components/dashboardTab/dashboard-list/dashboard-list.component';
import { VisualizationCreateComponent } from './components/visualizationTab/visualization-create/visualization-create.component';
import { VisualizationListComponent } from './components/visualizationTab/visualization-list/visualization-list.component';
import { VisualizationDetailsComponent } from './components/visualizationTab/visualization-details/visualization-details.component';
import { DashboardDetailsComponent } from './components/dashboardTab/dashboard-details/dashboard-details.component';
import { ChartComponent } from './components/chart/chart.component';
import { ChartDetailComponent } from './components/chart/chart-detail/chart-detail.component';
import { RoleListComponent } from './components/roleTab/role-list/role-list.component';
import { AddChartComponent } from './components/chart/add-chart/add-chart.component';
import { MapRoleActionComponent } from './components/roleTab/map-role-action/map-role-action.component';
import { CountryDetailComponent } from './components/countryTab/country-detail/country-detail.component';
import { AreaDetailComponent } from './components/area/area-detail/area-detail.component';
import { UserListRoleComponent } from './components/roleTab/user-list-role/user-list-role.component';
import { StoreDetailComponent } from './components/storesTab/store-detail/store-detail.component';
import { CountryManagerComponent } from './components/countryTab/country-manager/country-manager.component';
import { SalesAreaManagerComponent } from './components/area/sales-area-manager/sales-area-manager.component';
import { StoreManagerComponent } from './components/storesTab/store-manager/store-manager.component';
import { MappedPulseListComponent } from './components/pulseTab/mapped-pulse-list/mapped-pulse-list.component';
import { SpaceDetailComponent } from './components/spacesTab/space-detail/space-detail.component';

export const dashboardRoutes: Routes = [

  {
    path: RAIN.ROUTERLINKS.DASHBOARD,
    data: { breadcrumb: 'Home' },
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: RAIN.ROUTERLINKS.DASHBOARD_CREATE, component: DashboardCreateComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   New Dashboard' },
      },
      {
        path: RAIN.ROUTERLINKS.DASHBOARD_LIST, component: DashboardListComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   Dashboards' },
      },
      {
        path: RAIN.ROUTERLINKS.DASHBOARD_DETAIL, component: DashboardDetailsComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   Dashboard Detail' },
      },
      {
        path: RAIN.ROUTERLINKS.VISUALIZATION_CREATE, component: VisualizationCreateComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   New Visualization' },
      },
      {
        path: RAIN.ROUTERLINKS.VISUALIZATION_LIST, component: VisualizationListComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   Visualizations' },
      },
      {
        path: RAIN.ROUTERLINKS.VISUALIZATION_DETAIL, component: VisualizationDetailsComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   Visualization Detail' }
      },
      {
        path: RAIN.ROUTERLINKS.COUNTRY, component: CountryComponent,
        data: {
          breadcrumb: ' /   Country', role: ' /   ROLE_ADMIN'
        },
        // children: [
        //   {
        //     path: RAIN.ROUTERLINKS.CREATE_COUNTRY, component: CountryCreateComponent,
        //     data: { role: 'ROLE_ADMIN', breadcrumb: 'New Country' },
        //   },
        //   {
        //     path: RAIN.ROUTERLINKS.MAP_COUNTRY, component: CountryMappingComponent,
        //     data: { role: 'ROLE_ADMIN', breadcrumb: 'Map' },
        //   }
        // ]
      },
      {
        path: RAIN.ROUTERLINKS.CREATE_COUNTRY, component: CountryCreateComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   New Country' },
      },
      {
        path: RAIN.ROUTERLINKS.COUNTRY_DETAIL, component: CountryDetailComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /  Country Details' }
      },
      {
        path: RAIN.ROUTERLINKS.MAP_COUNTRY, component: CountryMappingComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   Map' },
      },
      {
        path: RAIN.ROUTERLINKS.COUNTRY_MANAGER, component: CountryManagerComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: '/ Assign Country Manager Role' }
      },
      {
        path: RAIN.ROUTERLINKS.SALES_AREA_MANAGER, component: SalesAreaManagerComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: '/ Assign Area Manager Role' }
      },
      {
        path: RAIN.ROUTERLINKS.STORE_MANAGER, component: StoreManagerComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: '/ Assign Store Manager Role' }
      },
      {
        path: RAIN.ROUTERLINKS.CREATE_AREA, component: AreaCreateComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   New Area' },
      },
      {
        path: RAIN.ROUTERLINKS.AREA_DETAIL, component: AreaDetailComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   Update Sales Area Detail' }
      },
      {
        path: RAIN.ROUTERLINKS.MAP_AREA, component: AreaMappingComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   Map' },
      },
      {
        path: RAIN.ROUTERLINKS.SALES_AREA, component: AreasComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   Areas' },
      },
      {
        path: RAIN.ROUTERLINKS.CREATE_STORES, component: StoreCreateComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   New Store' },
      },
      {
        path: RAIN.ROUTERLINKS.MAP_STORE, component: StoreMappingComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   Map' },
      },
      {
        path: RAIN.ROUTERLINKS.STORE, component: StoresComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   Stores' }
      },
      {
        path: RAIN.ROUTERLINKS.STORE_DETAIL, component: StoreDetailComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' / Update Store Detail' }
      },
      {
        path: RAIN.ROUTERLINKS.CREATE_SPACES, component: SpacesCreateComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   New Space' },
      },
      {
        path: RAIN.ROUTERLINKS.SPACE, component: SpaceComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   Spaces' }
      },
      {
        path: RAIN.ROUTERLINKS.SPACE_DETAIL, component: SpaceDetailComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: '/ Update Space Detail' }
      },
      {
        path: RAIN.ROUTERLINKS.CREATE_PULSE, component: PulseCreateComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   New Pulse' },
      },
      {
        path: RAIN.ROUTERLINKS.PULSE_CONFIG_LIST, component: PulseListComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   Pulses' }
      },
      {
        path: RAIN.ROUTERLINKS.PULSE_CONFIG_MAP, component: PulseConfigMapComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   Map' }
      },
      {
        path: RAIN.ROUTERLINKS.MAPPED_PULSE_LIST, component: MappedPulseListComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: '/ Mapped Pulses' }
      },
      {
        path: RAIN.ROUTERLINKS.CREATE_USER, component: UserCreateComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   New User' },
      },
      {
        path: RAIN.ROUTERLINKS.USER_LIST, component: UserListComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   Users' }
      },
      {
        path: RAIN.ROUTERLINKS.ADD_ROLE, component: AddRoleComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   New Role' },
      },
      {
        path: RAIN.ROUTERLINKS.ROLE_LIST, component: RoleListComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   Roles' }
      },
      {
        path: RAIN.ROUTERLINKS.USERS_BY_ROLE, component: UserListRoleComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   Mapped User List with Role' }
      },
      {
        path: RAIN.ROUTERLINKS.MAP_ROLE_ACTION, component: MapRoleActionComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: '/   Map Action to Role' }
      },
      {
        path: RAIN.ROUTERLINKS.ORG_INFO, component: OrgInfoComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   Organization Details' },
      },
      {
        path: RAIN.ROUTERLINKS.CHART, component: ChartComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: " /   New Chart" }
      },
      {
        path: RAIN.ROUTERLINKS.CHART_INFORMATION, component: ChartDetailComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   Chart Detail' }
      },
      {
        path: RAIN.ROUTERLINKS.ADD_CHART, component: AddChartComponent,
        data: { role: 'ROLE_ADMIN', breadcrumb: ' /   ADD New Chart' }
      }
    ]
  }
];