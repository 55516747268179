import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CountryService } from 'src/app/services/country/country.service';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
@Component({
  selector: 'app-country-mapping',
  templateUrl: './country-mapping.component.html',
  styleUrls: ['./country-mapping.component.scss']
})
export class CountryMappingComponent implements OnInit {

  // @Input() shareOrgIdWithMap: string;
  drag = false;
  i: any;
  indexName = 'Select a Country';
  indexName1 = 'Select a Country';
  salesArea = [];
  tempObj = [];
  salesArea1 = [];
  tempObj1 = [];

  countryControl = new FormControl('', [Validators.required]);
  constructor(private countryService: CountryService, private cookiesService: CookiesService) {
  }

  shareOrgIdWithMap: string;
  ngOnInit() {
    this.shareOrgIdWithMap = this.cookiesService.getCookie('orgId');
    this.getCountryListByOrgId(this.shareOrgIdWithMap);   // load country list in mapping component
    this.getSalesAreaListByOrgid(this.shareOrgIdWithMap);   // load sales area list in mapping component
  }

  ngOnChanges() {

  }

  countryList = [];
  countryCodeList = [];
  // GET countryList by passing orgId
  getCountryListByOrgId(orgId) {
    this.countryList = [];
    this.countryCodeList = [];
    this.countryService.getCountryListByParam(orgId)
      .subscribe(
        res => {
          for (const data of res.responseData) {
            this.countryList.push(data.name);
            this.countryCodeList.push(data.code);
          }
          console.log('country list', res);
        },
        err => {
          console.log(err);
        }
      );
  }

  unmappedSalesArea = [];
  // GET salesAreaList by passing orgId 
  getSalesAreaListByOrgid(orgId) {
    this.unmappedSalesArea = [];
    this.countryService.getSalesAreaListByParam(orgId)
      .subscribe(
        res => {
          this.unmappedSalesArea = res.responseData;
          console.log('unmapped Store Area ', this.unmappedSalesArea);
        },
        err => {
          console.log(err);
        });
  }

  selectedCountryCode: string;
  mappedSalesArea = [];
  // areaListObject;
  // GET Sales Area List by passing Country code and OrgId
  getSalesAreaListByCountryCodeAndOrgId(countryCode, orgId) {
    this.mappedSalesArea = [];
    this.countryService.getAreaByCountryAndOrgId(countryCode, orgId)
      .subscribe(
        res => {
          this.mappedSalesArea = res.responseData;
          console.log('this. mappedSalesARea ', this.mappedSalesArea);
        },
        err => {
          console.log(err);
        });
  }

  disabled: boolean = true;
  onClickCountry(index) {
    this.disabled = false;
    this.indexName = 'Select a Country';
    this.i = index;
    this.selectedCountryCode = this.countryCodeList[index];
    this.indexName = this.countryList[index];
    // this.drag = true;
    this.mappedSalesArea = [];
    this.getSalesAreaListByCountryCodeAndOrgId(this.selectedCountryCode, this.shareOrgIdWithMap);
    this.unmappedSalesArea = [];
    this.getSalesAreaListByOrgid(this.shareOrgIdWithMap);   // load sales area list in mapping component
  }

  unmapClick(areaId) {
    console.log('unmapped click area id: ', areaId);
    this.salesArea = [];
    this.salesArea.push({ id: areaId });
    this.unmapAreaFromCountry(this.selectedCountryCode, this.salesArea, this.shareOrgIdWithMap);
  }
  mapClick(areaId) {
    console.log('map click area id: ', areaId);
    this.tempObj1 = [];
    this.tempObj1 = this.mappedSalesArea;
    this.salesArea = [];
    for (var id1 of this.tempObj1) {
      this.salesArea.push({ id: id1.id });
    }
    this.salesArea.push({ id: areaId });
    console.log('salesArea ', this.salesArea);
    this.mapSalesAreaToCountry(this.selectedCountryCode, this.salesArea, this.shareOrgIdWithMap);
  }

  mapSalesAreaToCountry(countryCode, salesArea, orgId) {
    return this.countryService.mapSalesAreaToCountry(countryCode, salesArea, orgId)
      .subscribe(
        res => {
          console.log('response of map area to country API ', res);
          if (res.statusInfo.statusCode === 200) {
            console.log('I am checking condition');
            this.getSalesAreaListByOrgid(this.shareOrgIdWithMap);
            this.getSalesAreaListByCountryCodeAndOrgId(this.selectedCountryCode, this.shareOrgIdWithMap);
          }
        },
        err => {
          console.log(err);
        });
  }

  unmapAreaFromCountry(countryCode, salesArea, orgId) {
    this.countryService.unmapAreaFromCountry(countryCode, salesArea, orgId)
      .subscribe(res => {
        console.log('response of unmap area from country api: ', res);
        if (res.statusInfo.statusCode === 200) {
          this.getSalesAreaListByCountryCodeAndOrgId(this.selectedCountryCode, this.shareOrgIdWithMap);
          this.getSalesAreaListByOrgid(this.shareOrgIdWithMap);
        }
      },
        error => {
          console.log('error message: ', error);
        }
      )
  }


}
