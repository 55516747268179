import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { RAIN } from '../../app-constants';
import { environment } from '../../../environments/environment';
import { ErrorService } from '../error/error.service';
import { catchError, retry } from 'rxjs/operators';
import { CookiesService } from '../cookies/cookies.service';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  ENV: any = environment;
  constructor(private httpClient: HttpClient, private errorHandler: ErrorService, private cookiesService: CookiesService) { }

  // Area-country mapping related URLs
  private GET_COUNTRY_BY_PARAM_URL = RAIN.BASE + RAIN.USER + RAIN.ORG + RAIN.APIS.GET_COUNTRY_LIST;
  private GET_SALES_AREA_BY_COUNTRY_CODE_AND_ORG_ID_URL = RAIN.BASE + RAIN.MASTER + RAIN.SALES_AREA + RAIN.APIS.GET_SALES_AREA;
  private MAP_SALES_AREA_TO_COUNTRY_URL = RAIN.BASE + RAIN.MASTER + RAIN.SALES_AREA + RAIN.APIS.MAP_SALES_AREA_TO_COUNTRY;
  private UNMAP_SALES_AREA_FROM_COUNTRY_URL = RAIN.BASE + RAIN.MASTER + RAIN.SALES_AREA + RAIN.APIS.UNMAP_SALES_AREA_TO_COUNTRY;
  private ADD_COUNTRY_URL = RAIN.BASE + RAIN.USER + RAIN.APIS.CREATE_OR_UPDATE_COUNTRY;
  private DELETE_COUNTRY_URL = RAIN.BASE1 + RAIN.USER + RAIN.APIS.DELETE_COUNTRY;
  private GET_COUNTRY_LIST_URL = 'https://restcountries.eu/rest/v2/all';
  private ADD_COUNTRY_MANAGER = RAIN.BASE + RAIN.MASTER + RAIN.APIS.ADD_COUNTRY_MANAGER;

  // private DELETE_COUNTRY_URL = 'http://localhost:8081/user/deleteCountry';
  // GET country list by orgID
  getCountryListByParam(orgId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      }),
      params: new HttpParams().set('orgId', orgId)  // add single param
    };
    return this.httpClient.get<any>(this.GET_COUNTRY_BY_PARAM_URL, httpOptions).pipe(map(res => {
      console.log('get country by orgId res:=> ', res);
      return res;
    }, error => {
      console.log('error res: ', error);
    }));
    // .pipe(
    //   catchError(this.errorHandler.handleError)
    // );
  }

  // GET Sales Area List by orgId
  getSalesAreaListByParam(orgId) {
    let params = new HttpParams();
    params = params.append('isActive', 'true');
    params = params.append('orgId', orgId);
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: params
    }
    return this.httpClient.get<any>(this.GET_SALES_AREA_BY_COUNTRY_CODE_AND_ORG_ID_URL, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }

  // GET Area List by Country Code and OrgId
  getAreaByCountryAndOrgId(countryCode, orgId) {
    let params = new HttpParams();
    params = params.append('isActive', 'true');
    params = params.append('orgId', orgId);
    params = params.append('countryCode', countryCode);
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: params
    }
    return this.httpClient.get<any>(this.GET_SALES_AREA_BY_COUNTRY_CODE_AND_ORG_ID_URL, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }

  // POST call - mapping Area-country
  mapSalesAreaToCountry(countryCode, salesArea, orgId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      })
    };
    return this.httpClient.post<any>(this.MAP_SALES_AREA_TO_COUNTRY_URL, { countryCode, salesArea, orgId }, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }

  // POST call - unmap area from country
  unmapAreaFromCountry(countryCode, salesArea, orgId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      })
    };
    return this.httpClient.post<any>(this.UNMAP_SALES_AREA_FROM_COUNTRY_URL, { countryCode, salesArea, orgId }, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }

  // POST Call - add new Country
  addCountry(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      })
    };
    return this.httpClient.post<any>(this.ADD_COUNTRY_URL, data, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }

  // POST call - delete country
  deleteCountry(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      })
    };
    return this.httpClient.post<any>(this.DELETE_COUNTRY_URL, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getCountryList() {
    return this.httpClient.get<any>(this.GET_COUNTRY_LIST_URL);
  }

  addCountryManager(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      })
    };
    return this.httpClient.post<any>(this.ADD_COUNTRY_MANAGER, data, httpOptions);
  }

}
