import { Component, OnInit } from '@angular/core';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { PulseService } from 'src/app/services/pulse/pulse.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RAIN } from 'src/app/app-constants';

@Component({
  selector: 'app-mapped-pulse-list',
  templateUrl: './mapped-pulse-list.component.html',
  styleUrls: ['./mapped-pulse-list.component.scss']
})
export class MappedPulseListComponent implements OnInit {

  orgId: any;
  constructor(private cookiesService: CookiesService, private pulseService: PulseService, private matSnackBar: MatSnackBar) {
    this.orgId = this.cookiesService.getCookie('orgId');
    this.getAllMappedPulseList();
  }

  ngOnInit() {
  }

  qrValue: string = 'tarento.com';
  rowNo: any = [];
  mappedPulseList: any = [];
  btnName = 'Generate';
  getAllMappedPulseList() {
    this.pulseService.getAllMappedPulse(this.orgId)
      .subscribe(res => {
        this.mappedPulseList = res.responseData;
        // tslint:disable-next-line: forin
        for (let i in res.responseData) {
          this.rowNo = i + 1;
        }
        console.log('get mapped pulse list: ', res);
      }, error => {
        console.log('Error message: ', error);
      });
  }

  imageUrl;
  getImage(pulse): void {
    this.showQr = true;
    this.qrValue = pulse.url;
    console.log('qrvalue', this.qrValue);

  }
  getImageDownload(pulse) {
    const canvas = document.querySelector("canvas") as HTMLCanvasElement;
    console.log('canvas', canvas);
    let imageData = canvas.toDataURL("image/jpeg").toString();
    this.imageUrl = imageData;
  }

  encodedObjectValue: any;
  showQr: boolean = false;
  selectedPulse: any;
  generate(pulse) {
    this.selectedPulse = pulse;
    console.log('selected store space: ', pulse);
    this.qrValue = RAIN.PULSE_FEEDBACK_DOMAIN + RAIN.RATING + btoa('orgId=' + this.orgId + '&storeCode='
      + pulse.storeId + '&spaceCode=' + pulse.spaceId);
    this.showQr = true;
    this.updateQRCode();
  }

  onClickDelete(pulse) {
    console.log('on click delete pulse:', pulse);
    let data = {
      'storeId': pulse.storeId,
      'spaceId': pulse.spaceId
    }
    this.deletePulseMapping(data);
  }

  updateQRCode() {
    let data =
    {
      'storeId': +this.selectedPulse.storeId,
      'spaceId': +this.selectedPulse.spaceId,
      'url': this.qrValue
    };
    this.pulseService.updateQrCode(data)
      .subscribe(
        res => {
          if (res.statusInfo.statusCode === 200) {
            this.matSnackBar.open('QR Code generated and stored', 'SUCCESS', { duration: 3000 });
            this.getAllMappedPulseList();
          }
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }

  deletePulseMapping(data) {
    this.pulseService.deletePusleMapping(data)
      .subscribe(res => {
        if (res.statusInfo.statusCode === 200) {
          this.getAllMappedPulseList();
          this.matSnackBar.open('Pulse mapping deleted', 'SUCCESS', { duration: 2000 });
        }
      }, error => {
        console.log('error message: ', error);
      })
  }
}
