import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { RAIN } from '../../app-constants';
import { environment } from '../../../environments/environment';
import { CookiesService } from '../cookies/cookies.service';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  ENV: any = environment;
  constructor(private httpClient: HttpClient, private cookiesService: CookiesService) { }
  ADD_USER_URL = RAIN.BASE + RAIN.USER + RAIN.APIS.CREATE_OR_UPDATE;
  // ADD_USER_URL = 'http://localhost:8081/' + RAIN.USER + RAIN.APIS.CREATE_OR_UPDATE;

  // GET_USER_LIST_URL = 'http://localhost:8081/' + RAIN.USER + RAIN.APIS.GET_ALL_USER;

  GET_USER_LIST_URL = RAIN.BASE + RAIN.USER + RAIN.APIS.GET_ALL_USER;
  ASSIGN_ROLE_TO_USER_URL = RAIN.BASE + RAIN.USER + RAIN.ROLE + RAIN.APIS.MAPPING;
  REVOKE_ROLE_FROM_USER = RAIN.BASE + RAIN.USER + RAIN.ROLE + RAIN.MAPPING + RAIN.APIS.DELETE;

  GET_ROLE_LIST_URL = RAIN.BASE + RAIN.USER + RAIN.APIS.ROLES;
  GET_ROLE_LIST_URL_temp = RAIN.BASE + RAIN.USER + RAIN.APIS.ROLES;

  GET_ROLE_LIST_BY_USER_ID_AND_ORGID_URL = RAIN.BASE + RAIN.USER + RAIN.USER;
  GET_ROLE_LIST_BY_USER_ID_AND_ORGID_URL_temp = RAIN.BASE + RAIN.USER + RAIN.USER;

  DELETE_USER_URL = RAIN.BASE1 + RAIN.USER + RAIN.APIS.DELETE_USER;
  // DELETE_USER_URL = 'http://localhost:8081/' + RAIN.USER + RAIN.APIS.DELETE_USER;\
  GET_USERS_BY_MASTER_ROLE = RAIN.BASE + RAIN.USER + RAIN.APIS.GET_USERS_BY_MASTER_ROLE;
  GET_MASTER_ROLE_LIST = RAIN.BASE1 + RAIN.USER + RAIN.APIS.GET_MASTER_ROLE_BY_ORG_DOMAIN;


  // GET call - getting user list
  getUserListByOrgId(orgId) {
    orgId = +orgId;
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: new HttpParams().set('orgId', orgId)
    };
    return this.httpClient.get<any>(this.GET_USER_LIST_URL, httpOptions);
  }

  // add user
  addUser(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      })
    };
    data.isActive = +data.isActive;
    data.createdBy = +data.createdBy;
    return this.httpClient.post<any>(this.ADD_USER_URL, data, httpOptions);
  }

  // assign role to user
  assignRoleToUser(userId, roles, orgId) {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    }
    return this.httpClient.post<any>(this.ASSIGN_ROLE_TO_USER_URL, { userId, roles, orgId }, httpOptions)
  }

  // revoke role from user
  revokeRoleFromUser(userId, roles, orgId) {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    }
    return this.httpClient.post<any>(this.REVOKE_ROLE_FROM_USER, { userId, roles, orgId }, httpOptions)
      .pipe(map(
        res => { console.log('revoke role res: ', res); return res; },
        error => { console.log(error); }
      ));
  }

  // GET call - getting role list which are unmapped 
  getRoleListByOrgId(orgId) {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    };
    this.GET_ROLE_LIST_URL = this.GET_ROLE_LIST_URL_temp;
    this.GET_ROLE_LIST_URL = this.GET_ROLE_LIST_URL + '/' + orgId;
    return this.httpClient.get<any>(this.GET_ROLE_LIST_URL, httpOptions)
      .pipe(map(res => { return res; }, error => { console.log(error); }));
  }

  userId;
  // GET role list by userId and orgId
  getRoleListByUserIdAndOrgId(id, orgId) {
    orgId = +orgId;
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: new HttpParams().set('orgId', orgId)
    }
    this.GET_ROLE_LIST_BY_USER_ID_AND_ORGID_URL = this.GET_ROLE_LIST_BY_USER_ID_AND_ORGID_URL_temp;
    this.GET_ROLE_LIST_BY_USER_ID_AND_ORGID_URL = this.GET_ROLE_LIST_BY_USER_ID_AND_ORGID_URL + id;
    return this.httpClient.get<any>(this.GET_ROLE_LIST_BY_USER_ID_AND_ORGID_URL, httpOptions)
      .pipe(map(
        res => {
          console.log('get assigned role list: ', res);
          return res;
        },
        error => { console.log(error); }));
  }

  getRoleListByVisualizationId(id) {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: new HttpParams().set('id', id)
    }

  }

  deleteUser(data) {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    };

    return this.httpClient.post<any>(this.DELETE_USER_URL, data, httpOptions)
      .pipe(map(
        res => {
          return res;
        },
        error => { console.log(error); }));
  }

  getUsersByMasterRole(userMasterRoleDto) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      })
    };
    return this.httpClient.post<any>(this.GET_USERS_BY_MASTER_ROLE, userMasterRoleDto, httpOptions);
  }
  getUserListByMasterRole(masterRoleCode, orgId) {
    let params = new HttpParams();
    params = params.append('role', masterRoleCode);
    params = params.append('orgId', orgId);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      }),
      params: params
    };
    return this.httpClient.get<any>(this.GET_USERS_BY_MASTER_ROLE, httpOptions);
  }

  getMasterRoleByOrgDomain(org_domain_id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      }),
      params: new HttpParams().set("org_domain_id", org_domain_id)
    };
    return this.httpClient.get<any>(this.GET_MASTER_ROLE_LIST, httpOptions);
  }

}
