import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ChartService } from 'src/app/services/chart/chart.service';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-chart',
  templateUrl: './add-chart.component.html',
  styleUrls: ['./add-chart.component.scss']
})
export class AddChartComponent implements OnInit {

  orgId;
  chartGroup: FormGroup;

  chartServiceControl = new FormControl();
  chartTypeControl = new FormControl();
  chartsName = { name: [] };

  constructor(private cookiesService: CookiesService, private formBuilder: FormBuilder,
    private chartService: ChartService,
    private _snackBar: MatSnackBar, private router: Router) {
    this.orgId = this.cookiesService.getCookie('orgId');
    this.getServiceAPIDetail();
    // this.getChartList();
  }

  ngOnInit() {
    this.chartGroup = this.formBuilder.group({
      chartName: ['', Validators.required],
      chartCode: ['', Validators.required],
      description: ['', Validators.required],
      // filter: ['', Validators.required],
      isActive: [''],
    });
  }

  get f() { return this.chartGroup.controls; }
  submitted: boolean = false;
  onSubmit(f) {
    this.submitted = true;
    if (this.chartGroup.valid) {
      this.addNewChart();
    }
    return;
  }

  charts = [];
  searchText;
  disabled: boolean = true;
  selectedServiceApiId: any;
  onSelectService(event) {
    this.selectedServiceApiId = event.value.id;
    this.disabled = false;
    this.getChartType(this.selectedServiceApiId);
  }

  selectedChartType: any;
  selectedFilter: any;
  onSelectChartType(chart) {
    this.selectedChartType = chart.value.chartType;
    this.selectedFilter = chart.value.filter;
  }

  serviceApis: any = [];
  getServiceAPIDetail() {
    this.chartService.getServiceApiList()
      .subscribe(
        res => {
          this.serviceApis = res;
          console.log('get service_api response ', res);
        },
        error => { console.log('error message: ', error); }
      );
  }

  addNewChart() {
    console.log('isActive', this.chartGroup.value);
    let data = {
      name: this.chartGroup.value.chartName,
      code: this.chartGroup.value.chartCode,
      description: this.chartGroup.value.description,
      filter: this.selectedFilter,
      serviceApi: +this.selectedServiceApiId,
      isActive: +this.chartGroup.value.isActive,
      chartType: this.selectedChartType,
      orgId: +this.orgId
    };
    console.log('add chart data ', data);
    this.chartService.addNewChart(data)
      .subscribe(
        res => {
          console.log('add new chart response: ', res);
          if (res.statusInfo.statusCode === 200) {
            this._snackBar.open('New chart created successfully!', 'SUCCESS', {
              duration: 2000,
            });
            this.router.navigate(['/dashboard/chart']);
            // this.getChartList();
          }
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }

  getChartType(chartTypeId) {
    this.chartService.getChartTypeByApi(chartTypeId)
      .subscribe(res => {
        console.log('chart type response', res);
        this.chartsName = res.responseData;
      }, error => {
        console.log('error message: ', error);
      });
  }
}
