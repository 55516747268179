import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { RAIN } from '../../app-constants';
import { environment } from '../../../environments/environment';
import { CookiesService } from '../cookies/cookies.service';
import { ErrorService } from '../error/error.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

  ENV: any = environment;
  private LOGIN_URL = RAIN.BASE1 + RAIN.APIS.LOGIN;
  private TOKEN_VALIDATION_URL = RAIN.BASE1 + RAIN.USER + RAIN.APIS.TOKEN_VALIDATE;

  constructor(private httpClient: HttpClient, private router: Router, private _cookiesService: CookiesService,
    private errorHandler: ErrorService) { }

  isAuthenticated(): boolean {
    // if (this._cookiesService.getCookie('role') && this._cookiesService.getCookie('authToken')) {
    if (this._cookiesService.getCookie('authToken')) {
      return true;
    }
    return false;
  }

  // POST login api call
  authentication(username, password) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };
    return this.httpClient.post<any>(this.LOGIN_URL, { username, password }, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }

  // GET request for token validation
  checkSuperAdminToken() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': this._cookiesService.getCookie('authToken')
      })
    };
    console.log('service checksuperadmin');
    return this.httpClient.get<any>(this.TOKEN_VALIDATION_URL, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }

  // clear the token
  logOut() {
    this.router.navigate(['/login']);
    this._cookiesService.clear();
  }

  // checks whether users is logged in
  // loggedIn() {
  //   return !!(localStorage.getItem('token'));
  // }
}
