import { Component, OnInit, Input } from '@angular/core';
import { CountryService } from 'src/app/services/country/country.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-country-create',
  templateUrl: './country-create.component.html',
  styleUrls: ['./country-create.component.scss']
})
export class CountryCreateComponent implements OnInit {

  added = false;
  countryForm: FormGroup;
  successMessage: string;
  dataInsertBool = true;
  name;
  code;
  currency;
  phoneCode;
  organizationId;
  submitted: boolean = false;
  myFormControl = new FormControl();


  constructor(private countryService: CountryService, private cookiesService: CookiesService,
    private router: Router, private formBuilder: FormBuilder, private snackBar: MatSnackBar) {

  }
  ngOnInit() {
    this.getCountryList();
  }


  countryList: any = [];
  getCountryList() {
    this.countryService.getCountryList()
      .subscribe(
        res => {
          this.countryList = res;
        }, error => { console.log('error message: get country list from https://restcountries.eu/rest/v2/all api'); }
      )
  }
  selectedCountry: any;
  detailsFlag: boolean = false;
  onSelectCountry(event) {
    let countryObj = {
      'name': '',
      'currency': '',
      'phoneCode': '',
      'logoUrl': '',
      'code': '',
      'orgId': +this.cookiesService.getCookie('orgId')
    };
    countryObj.name = event.value.name;
    countryObj.phoneCode = event.value.callingCodes[0];
    countryObj.currency = event.value.currencies[0].code;
    countryObj.logoUrl = event.value.flag;
    countryObj.code = event.value.alpha3Code;
    this.disabled = false;
    this.selectedCountry = countryObj;
    this.detailsFlag = true;
  }

  disabled: boolean = true;
  onClickAdd() {
    this.countryService.addCountry(this.selectedCountry)
      .subscribe(res => {
        if (res.statusInfo.statusCode === 200) {
          this.dataInsertBool = false;
          // this.successMessage = 'country details added successfully';
          this.snackBar.open('organization created successfully', 'SUCCESS', {
            duration: 2000
          });
          this.router.navigate(['/dashboard/country']);
        }
      },
        error => {
          console.log(error);
        });
  }

}
