import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-visualization-create',
  templateUrl: './visualization-create.component.html',
  styleUrls: ['./visualization-create.component.scss']
})
export class VisualizationCreateComponent implements OnInit {

  orgId;
  constructor(private formBuilder: FormBuilder, private cookiesService: CookiesService,
    private dashboardService: DashboardService, private _snackBar: MatSnackBar, private router: Router) {
    this.orgId = this.cookiesService.getCookie('orgId');
  }

  visualizationGroup: FormGroup;

  ngOnInit() {
    this.visualizationGroup = this.formBuilder.group({
      visualizationName: ['', Validators.required],
      description: ['', Validators.required],
      visualRank: ['', Validators.required],
      isActive: [''],
    });
  }

  get f() { return this.visualizationGroup.controls; }
  submitted: boolean = false;
  onSubmit(f) {
    this.submitted = true;
    if (this.visualizationGroup.valid) {
      this.addNewVisualization()
    }
    return;
  }

  addNewVisualization() {
    // addNewVisualization(name, description, isActive, visualRank) {
    let data = {
      name: this.visualizationGroup.value.visualizationName,
      visual_rank: +this.visualizationGroup.value.visualRank,
      isActive: +this.visualizationGroup.value.isActive,
      description: this.visualizationGroup.value.description,
      orgId: +this.cookiesService.getCookie('orgId'),
    };
    // const data = {
    //   name: name.value,
    //   description: description.value,
    //   isActive: +isActive.value,
    //   visual_rank: +visualRank.value,
    //   orgId: +this.orgId
    // };
    console.log(data);
    this.dashboardService.addNewVisualization(data)
      .subscribe(
        res => {
          if (res.statusInfo.statusCode === 200) {
            this._snackBar.open('New visualization created successfully!', 'SUCCESS', {
              duration: 2000,
            });
            this.router.navigate(['/dashboard/visualizationList']);
          }
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }

}
