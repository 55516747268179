
import { Routes } from '@angular/router';
import { LoginComponent } from './core/login/login.component';
import { OrganizationComponent } from './modules/dashboard/components/organization/organization.component';
import { AuthGuard } from './shared/helpers/guards/auth.guard';
// import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

export const APP_ROUTES: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'org', component: OrganizationComponent,
    data: { breadcrumb: 'SuperAdmin Dashboard' },
    canActivate: [AuthGuard]
  },
  //   { path: '**', component: PageNotFoundComponent }
];