import { Component, OnInit } from '@angular/core';
import { PulseService } from 'src/app/services/pulse/pulse.service';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pulse-list',
  templateUrl: './pulse-list.component.html',
  styleUrls: ['./pulse-list.component.scss']
})
export class PulseListComponent implements OnInit {

  orgId;
  searchText;
  flagPulseList = false;
  flagPulseInfo = false;
  constructor(private pulseService: PulseService, private cookiesService: CookiesService,
    private _snackBar: MatSnackBar, private router: Router) {
    this.orgId = cookiesService.getCookie('orgId');
    this.getPulseRatingConfigurationList(this.orgId);
  }

  ngOnInit() {

  }

  useDefault;
  public toggle(event: MatSlideToggleChange, pulse) {
    console.log('toggle', event.checked);
    this.useDefault = event.checked;
    this.enableAgeGroup(pulse.id, this.useDefault);
  }


  routerFlag: boolean = false;
  hideFlag: boolean = true;
  mapPulseFlag() {
    this.hideFlag = false;
    this.routerFlag = true;
  }

  addPulseFlag() {
    this.hideFlag = false;
    this.routerFlag = true;
  }

  mappedPulseList() {
    this.router.navigate(['/dashboard/mappedPulseList']);
  }

  pulse;
  onSubmit(pulses) {
    this.flagPulseList = false;
    this.flagPulseInfo = true;
    this.pulse = pulses;
    this.hideFlag = false;
    this.routerFlag = true;
    this.cookiesService.putCookie('pulseId', this.pulse.id);
    this.getPulseRating(this.pulse.id);
  }

  onCancel() {
    this.hideFlag = true;
    this.routerFlag = false;
    this.flagPulseInfo = false;
    this.flagPulseList = true;
  }

  pulseList: any = [];
  // GET pulse rating configuratiion list
  getPulseRatingConfigurationList(orgId) {
    this.pulseService.getPulseRatingConfigList(orgId)
      .subscribe(
        res => {
          console.log('getPulseRatingConfigList', res);
          this.pulseList = res;
        },
        error => { console.log(error); }
      );
  }

  pulseConfigDetail;
  // GET pulse configuration details by pulse configuration id
  getPulseRating(id) {
    this.pulseService.getPulseRatingConfig(id)
      .subscribe(
        res => {
          this.pulseConfigDetail = res;
          // this.cookiesService.putCookie('brandColor', res.responseData.brandColor);
          localStorage.setItem('themeColor', res.responseData.themeColor);
        },
        error => {
          console.log(error);
        }
      );
  }

  onDeletePulse(pulse) {
    this.flagPulseInfo = false;
    console.log('selected pulse values ', pulse['id'], ' ', this.orgId);
    this.deletePulseConfiguration(pulse['id']);
  }

  deletePulseConfiguration(pulseId) {
    this.pulseService.deletePulseConfiguration(pulseId).subscribe(res => {
      if (res.statusInfo.statusCode === 200) {
        this._snackBar.open('pulse configuration deleted successfully', 'SUCCESS', {
          duration: 2000,
        });
        this.getPulseRatingConfigurationList(this.orgId);
      }
    });
  }

  enableAgeGroup(id, enableAgeGroup) {
    let data = {
      'id': id,
      "enableAgeGroup": enableAgeGroup
    };
    this.pulseService.enableAgeGroup(data)
      .subscribe(
        res => {
          if (res.statusInfo.statusCode === 200) {
            this._snackBar.open('Age Group field page status has been changed', 'SUCCESS', { duration: 3000 });
          }
        }, error => {
          console.log('error message: ', error);
        }
      );
  }

}
