import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { ChartService } from 'src/app/services/chart/chart.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {

  orgId: any;
  constructor(private cookiesService: CookiesService, private formBuilder: FormBuilder,
    private chartService: ChartService,
    private _snackBar: MatSnackBar, private router: Router) {

    this.orgId = this.cookiesService.getCookie('orgId');
    this.getChartList();
  }

  ngOnInit() {
  }

  onDelete(chart) {
    this.deleteChart(chart.id);
  }

  onClickChart(chart) {
    console.log('selected chart: ', chart);
    this.cookiesService.putCookie('chartId', chart.id);
    // this.router.navigateByUrl('dashboard/chartInformation', chart);
    this.router.navigate(['dashboard/chartInformation']);
  }

  deleteChart(chartId) {
    this.chartService.deleteChartById(chartId)
      .subscribe(
        res => {
          console.log('')
          if (res.statusInfo.statusCode === 200) {
            this._snackBar.open('chart deleted successfully!', 'SUCCESS', {
              duration: 2000,
            });
            this.getChartList();
          }
        },
        err => {
          console.log(err);
        }
      );
  }

  selectedServiceApiId: any;
  onSelectServiceApi(serviceApi) {
    this.selectedServiceApiId = serviceApi.id;
  }

  selectedChartType: any;
  onSelectChartType(chart) {
    this.selectedChartType = chart;
  }

  onSlideClick(event: MatSlideToggleChange, chart) {
    let data = chart;
    data['isActive'] = +event.checked;
    this.updateChartDetail(data);
  }

  updateChartDetail(data) {
    this.chartService.updateChartDetails(data)
      .subscribe(
        res => {
          if (res.statusInfo.statusCode === 200) {
            // this.getChartList();
            this._snackBar.open('chart detail is updated successfully', 'SUCCESS', {
              duration: 1000
            });
          }
          // this.getChartList();
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }

  charts = [];
  searchText;
  getChartList() {
    this.chartService.getChartList(this.orgId).subscribe(
      res => {
        this.charts = res.responseData;
        console.log('chart list ', this.charts);
      },
      error => {
        console.log('error message ', error);
      }
    )
  }

  goToAddChart() {
    this.router.navigate(['/dashboard/addChart']);
  }

}
