import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { RAIN } from '../../app-constants';
import { environment } from '../../../environments/environment';
import { CookiesService } from '../cookies/cookies.service';
import { runInContext } from 'vm';
@Injectable({
  providedIn: 'root'
})
export class PulseService {

  ENV: any = environment;
  constructor(private httpClient: HttpClient, private cookiesService: CookiesService) { }

  GET_PULSE_RATING_CONFIG_BY_CONFIG_ID_URL = RAIN.BASE + RAIN.MASTER + RAIN.APIS.GET_PULSE_RATING_CONFIG;
  ADD_OR_UPDATE_PULSE_CONFIG_URL = RAIN.BASE + RAIN.MASTER + RAIN.APIS.ADD_OR_UPDATE_PULSE_CONFIG;
  GET_PULSE_RATING_CONFIG_LIST_URL = RAIN.BASE + RAIN.MASTER + RAIN.APIS.GET_ALL_PULSE_RATING_CONFIG;
  MAP_PULSE_CONFIGURATION_WITH_STORE_AND_SPACE_URL = RAIN.BASE + RAIN.MASTER + RAIN.APIS.UPDATE_CONFIG_TO_STORE_SPACE;
  DELETE_PULSE_CONFIGURATION_URL = RAIN.BASE + RAIN.MASTER + RAIN.APIS.DELETE_PULSE_CONFIG;
  TEMP_DELETE_PULSE_CONFIGURATION_URL = RAIN.BASE + RAIN.MASTER + RAIN.APIS.DELETE_PULSE_CONFIG;
  GET_ALL_MAPPED_PULSE = RAIN.BASE + RAIN.MASTER + RAIN.APIS.GET_ALL_PULSE_MAP;
  UPDATE_QRCODE = RAIN.BASE + RAIN.MASTER + RAIN.APIS.UPDATE_QR_CODE;
  ENABLE_AGE_GROUP = RAIN.BASE + RAIN.MASTER + RAIN.APIS.ENABLE_AGE_GROUP;
  DELETE_PULSE_MAPPING = RAIN.BASE + RAIN.MASTER + RAIN.APIS.DELETE_PULSE_CONFIG_TO_STORE_SPACE;
  UPDATE_PULSE_CONFIG=RAIN.BASE+RAIN.MASTER+RAIN.APIS+'';
  // GET pulse rating configuration
  getPulseRatingConfig(id) {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: new HttpParams().set('id', id)
    };
    return this.httpClient.get<any>(this.GET_PULSE_RATING_CONFIG_BY_CONFIG_ID_URL, httpOptions);
  }

  // GET pulse config list by orgId
  getPulseRatingConfigList(orgId) {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: new HttpParams().set('orgId', orgId)
    }
    return this.httpClient.get<any>(this.GET_PULSE_RATING_CONFIG_LIST_URL, httpOptions);
  }

  // POST request for add or update pulse configuration
  addOrUpdatePulseConfig(pulse) {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken'),
          'Accept': 'application/json'
        }
      )
    };
    return this.httpClient.post<any>(this.ADD_OR_UPDATE_PULSE_CONFIG_URL,
      pulse, httpOptions);
  }

  // POST request for mapping pulse configuration with store and space
  mapPulseConfigurationWithStoreAndSpace(mapPulseObj) {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken'),
          'Accept': 'application/json'
        }
      )
    };
    return this.httpClient.post<any>(this.MAP_PULSE_CONFIGURATION_WITH_STORE_AND_SPACE_URL, mapPulseObj, httpOptions);
  }

  // DELETE pulse configuration
  deletePulseConfiguration(id) {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
      // ,
      // params: new HttpParams().set('id', id)
    }
    this.DELETE_PULSE_CONFIGURATION_URL = this.TEMP_DELETE_PULSE_CONFIGURATION_URL;
    this.DELETE_PULSE_CONFIGURATION_URL = this.DELETE_PULSE_CONFIGURATION_URL + '/' + id;
    return this.httpClient.get<any>(this.DELETE_PULSE_CONFIGURATION_URL, httpOptions);
  }

  // GET ALL MAPPED PULSE By OrgId
  getAllMappedPulse(orgId) {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: new HttpParams().set('orgId', orgId)
    };
    return this.httpClient.get<any>(this.GET_ALL_MAPPED_PULSE, httpOptions);
  }

  updateQrCode(data) {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          Authorization: this.cookiesService.getCookie('authToken')
        }
      )
    };
    return this.httpClient.post<any>(this.UPDATE_QRCODE, data, httpOptions);
  }

  enableAgeGroup(data) {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    }
    return this.httpClient.post<any>(this.ENABLE_AGE_GROUP, data, httpOptions);
  }

  deletePusleMapping(data) {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    };
    return this.httpClient.post<any>(this.DELETE_PULSE_MAPPING, data, httpOptions);
  }

  updatePulseConfiguration(data)
  {
      const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    };
    return this.httpClient.post<any>(this.UPDATE_PULSE_CONFIG, data, httpOptions);
  }

}
