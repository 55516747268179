import { AreaService } from 'src/app/services/area/area.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-area-create',
  templateUrl: './area-create.component.html',
  styleUrls: ['./area-create.component.scss']
})
export class AreaCreateComponent implements OnInit {

  addAreaForm: FormGroup;
  submitted: boolean = false;

  constructor(private formBuilder: FormBuilder, private areaService: AreaService, private router: Router,
    private cookiesService: CookiesService) { }
  ngOnInit() {
    this.addAreaForm = this.formBuilder.group({
      code: ['', Validators.required],
      name: ['', Validators.required],
      description: ['', Validators.required],
      isActive: ['false']
    });
  }

  get f() { return this.addAreaForm.controls; }

  onSubmit(f) {
    this.submitted = true;
    if (this.addAreaForm.valid) {
      this.onAddArea()
    }
    return;
  }

  successMessage: string;
  dataInsertBool = true;

  onAddArea() {
    let data =
    {
      name: this.addAreaForm.value.name,
      code: this.addAreaForm.value.code,
      description: this.addAreaForm.value.description,
      createdBy: +this.cookiesService.getCookie('createdBy'),
      isActive: +this.addAreaForm.value.isActive,
      orgId: +this.cookiesService.getCookie('orgId')
    };

    this.areaService.addSalesArea(data).subscribe(
      response => {
        console.log('response in adding Area ', response);
        if (response.statusInfo.statusCode === 200) {
          this.dataInsertBool = false;
          this.successMessage = 'sales area added successfully';
          this.router.navigate(['/dashboard/salesArea']);
        }
      },
      error => {
        console.log("error message: ", error);
      });
  }


}
