import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor() { }

  public handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = ` ${error.error.message}`
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if(error.message){
        errorMessage = ` ${error.message}`;
      } else if (error.error){
        errorMessage = ` ${error.error}`;
      }
      if(error.error.message){
        errorMessage = ` ${error.error.message}`;
      } else if (error.error){
        errorMessage = ` ${error.error}`;
      }
      
    }
    // return an observable with a user-facing error message
    return throwError(errorMessage);
  };

}


// Error Code: ${error.status}\nMessage: