import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationService } from 'src/app/services/organization/organization.service';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { FormControl } from '@angular/forms';
import { AwsSdkService } from 'src/app/services/aws-sdk/aws-sdk.service';
import { RoleService } from 'src/app/services/role/role.service';
@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {

  companyNames = [];
  logoUrl = [];
  orgId = [];
  orgData: any = [];
  orgDomainList = [];
  loader: boolean = false;
  myFormControl = new FormControl();

  saveOrgData: any = [];
  constructor(private router: Router, private route: ActivatedRoute, private orgService: OrganizationService,
    private cookiesService: CookiesService, private awsSdkService: AwsSdkService, private roleService: RoleService) {
    // Loading organization data in Dashboard component
    (this.orgService.getOrganization().subscribe(
      res => {
        this.loader = false;
        if (res.statusInfo.statusCode === 200) {
          this.orgData = res;
        }
      },
      error => {
        this.loader = false;
        console.log('error message from getOrganization API:', error);
      }
    )
    );
    this.getOrgDomainCode();
    this.changeText = false;
  }

  selectedOrgDomain: any;
  onClickOption(orgDomain) {
    this.selectedOrgDomain = orgDomain;
    // this.getOrgDomainRolesByDomainCode(this.selectedOrgDomain);
  }

  orgDomainCodes: any;
  ngOnInit() {
    this.loader = true;
    this.createdBy = this.cookiesService.getCookie('createdBy');
    this.getOrgDomainCode();
  }

  getOrgDomainCode() {
    this.orgService.getOrgDomain()
      .subscribe(
        res => {
          this.orgDomainCodes = res;
          console.log('res of org domain');
          // if (res.statusInfo.statusCode === 200) {
          // this.getOrgDomainRolesByDomainCode(this)
          // }
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }

  public selectedFiles: FileList;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  @ViewChild('orgLogo', { static: false }) orgLogo: any;
  public onFileChange(event) {
    this.selectedFiles = event.target.files;
    console.log(this.selectedFiles);
    // this.fileChanged = true;
    this.upload();
  }

  fileName: any;
  public upload() {
    // if (this.fileChanged == true) {
    const file = this.selectedFiles.item(0);
    this.fileName = file.name;
    this.awsSdkService.uploadfile(file);
    console.log('', file);
    // }
  }

  successMessage: string;
  dataInsertBool = true;
  name: string;
  orgLogoUrl: string;
  title: string;
  color: string;
  createdBy: any;
  domain: string;
  newOrgId: any;
  // Add organization service call for adding data
  onSubmitOrgDetails(name, orgLogoUrl, title, color) {
    this.name = name.value;
    this.orgLogoUrl = localStorage.getItem('brandLogoUrl');
    // this.orgLogoUrl = orgLogoUrl.value;
    this.title = title.value;
    this.color = color.value;
    this.createdBy = +this.createdBy;
    this.domain = this.selectedOrgDomain;
    (this.orgService.addOrganization(this.name, this.orgLogoUrl, this.title, this.color, this.createdBy, this.domain).subscribe(
      response => {
        if (response.statusInfo.statusCode === 200) {
          this.dataInsertBool = false;
          this.successMessage = 'org added successfully';
          this.newOrgId = response.responseData.id;
          this.getOrgDomainRolesByDomainCode(this.selectedOrgDomain);
          (this.orgService.getOrganization().subscribe(
            res => {
              this.loader = false;
              if (res.statusInfo.statusCode === 200) {
                this.orgData = res;
                localStorage.removeItem('brandLogoUrl');
              }

              this.dash = true;
              this.add = false;
            },
            error => {
              this.loader = false;
              console.log('error message from getOrganization API:', error);
            }
          )
          );
        }
      },
      error => {
        console.log('error message: ', error);
      }));
  }

  // dash to pulse
  goToPulse(company) {
    // console.log('selected company detail: ', company);
    this.cookiesService.putCookie('orgId', company.id);
    this.cookiesService.putCookie('orgDomain', company.orgDomain);
    localStorage.setItem('logoUrl', company.logoUrl);
    this.router.navigate(['/dashboard']);
    // this.router.navigate(['/pulse', company.id]);
  }

  addNewRole(data) {
    this.roleService.addRole(data)
      .subscribe(res => {
        // if (res.statusInfor.statusCode === 200) {
        //   this.dash = true;
        //   this.add = false;
        // }
        console.log('add new res ', res);
      }, error => {
        console.log('error message: ', error);
      }
      );
  }

  orgDomainRoles: any = [];
  getOrgDomainRolesByDomainCode(domainCode) {
    let data =
    {
      name: '',
      description: '',
      orgId: +this.newOrgId,
      // isAdmin:
      code: '',
      id: +''
    }
    this.roleService.getRolesByOrgDomain(domainCode)
      .subscribe(
        res => {
          this.orgDomainRoles = res.responseData;
          console.log('get org domain roles res: ', this.orgDomainRoles);
          if (res.statusInfo.statusCode === 200) {
            for (let i in this.orgDomainRoles) {
              data.name = this.orgDomainRoles[i].name;
              data.description = this.orgDomainRoles[i].description;
              data.code = this.orgDomainRoles[i].code;
              data.id = +this.orgDomainRoles[i].id;
              this.addNewRole(data);
            }

          }
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }


  add = false;
  delete = false;
  edit = false;
  dash = true;
  changeText: boolean;
  indexC: any;

  onSubmitAdd() {
    this.add = true;
    this.delete = false;
    this.edit = false;
    this.dash = false;
  }
  onSubmitEdit(i) {
    this.indexC = i;
    this.edit = true;
    this.delete = false;
    this.add = false;
    this.dash = false;
  }
  onSubmitDelete() {
    this.delete = true;
    this.add = false;
    this.edit = false;
  }

  onCancel() {
    this.dash = true;
    this.add = false;
  }

}
