import { Component, OnInit, SimpleChanges } from '@angular/core';
import { CountryService } from 'src/app/services/country/country.service';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class CountryComponent implements OnInit {

  constructor(private countryService: CountryService, private cookiesService: CookiesService,
    private _snackBar: MatSnackBar, private router: Router) { }

  orgId;
  ngOnInit() {
    this.orgId = this.cookiesService.getCookie('orgId');
    this.getCountryListByOrgId(this.orgId);
  }

  routerFlag: boolean = false;
  countries: any = [];
  // GET countryList by passing orgId
  getCountryListByOrgId(orgId) {
    this.countryService.getCountryListByParam(orgId)
      .subscribe(
        res => {
          this.countries = res;
          // this.countryData = res;
          console.log('country list', this.countries);
        },
        err => {
          console.log(err);
        }
      );
  }

  hideFlag: boolean = true;
  mapCountryFlag() {
    this.hideFlag = false;
    this.routerFlag = true;
  }

  addCountryFlag() {
    this.hideFlag = false;
    this.routerFlag = true;
  }

  // countryData = [];
  onDelete(country) {
    console.log('delete country data', country);
    country['orgId'] = +this.orgId;
    console.log('...', country);
    this.deleteCountry(country);
  }

  deleteCountry(country) {
    this.countryService.deleteCountry(country).subscribe(
      res => {
        console.log('delete api call response ', res);
        if (res.statusInfo.statusCode === 200) {
          this._snackBar.open('country is deleted successfully!', 'SUCCESS', {
            duration: 2000,
          });
          this.getCountryListByOrgId(this.orgId);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  onClickEdit(country) {
    localStorage.setItem('countryId', country.id);
    this.router.navigate(['/dashboard/countryDetail']);
  }
}
