import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { RAIN } from '../../app-constants';
import { environment } from '../../../environments/environment';
import { ErrorService } from '../error/error.service';
import { catchError, retry } from 'rxjs/operators';
import { CookiesService } from '../cookies/cookies.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  ENV: any = environment;
  constructor(private httpClient: HttpClient, private errorHandler: ErrorService, private cookiesService: CookiesService) { }

  private GET_ORG_LIST_URL = RAIN.BASE + RAIN.MASTER + RAIN.APIS.GET_ALL_ORG;
  private GET_ORG_BY_PARAM_URL = RAIN.BASE + RAIN.MASTER + RAIN.APIS.GET_ALL_ORG;
  private ADD_ORG_URL = RAIN.BASE + RAIN.MASTER + RAIN.APIS.ADD_ORG;
  private GET_ORG_DOMAIN_URL = RAIN.BASE + RAIN.MASTER + RAIN.APIS.ORG_DOMAIN;
  private GET_ORG_DETAILS_BY_ORG_ID = RAIN.BASE + RAIN.MASTER + RAIN.APIS.ORG;
  private TEMP_GET_ORG_DETAILS_BY_ORG_ID = RAIN.BASE + RAIN.MASTER + RAIN.APIS.ORG;
  // private UPDATE_ORG_DETAIL_URL = RAIN.BASE + RAIN.MASTER + RAIN.APIS.UPDATE_ORG_DETAIL;
  private UPDATE_ORG_DETAIL_URL = RAIN.BASE + RAIN.MASTER + RAIN.APIS.UPDATE_ORG_DETAIL;

  getOrganization() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'aapplication/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      })
    };
    return this.httpClient.get<any>(this.GET_ORG_LIST_URL, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }
  // POST add Organization call
  addOrganization(name, logoUrl, title, brandColor, createdBy, orgDomain) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      })
    };
    return this.httpClient.post<any>(this.ADD_ORG_URL, { name, logoUrl, title, brandColor, createdBy, orgDomain }, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }


  getOrgDomain() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.cookiesService.getCookie('authToken')
      })
    };
    return this.httpClient.get<any>(this.GET_ORG_DOMAIN_URL, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }

  getOrgDetailsByOrgId(orgId) {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-type': 'application/json',
          Authorization: this.cookiesService.getCookie('authToken')
        }
      ),
      params: new HttpParams().set('orgId', orgId)
    };
    // this.GET_ORG_DETAILS_BY_ORG_ID = '';

    return this.httpClient.get<any>(this.GET_ORG_BY_PARAM_URL, httpOptions)
      .pipe(catchError(this.errorHandler.handleError)
      );
  }

  updateOrgDetail(name, logoUrl, title, brandColor, orgDomain, id, orgCode, orgPin) {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          Authorization: this.cookiesService.getCookie('authToken')
        }
      )
    };
    return this.httpClient.put<any>(this.UPDATE_ORG_DETAIL_URL, { name, logoUrl, title, brandColor, orgDomain, id, orgCode, orgPin }, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }
}