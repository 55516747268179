import {
  Component,
  OnInit
} from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { PulseService } from 'src/app/services/pulse/pulse.service';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-pulse',
  templateUrl: './pulse.component.html',
  styleUrls: ['./pulse.component.scss']
})


export class PulseComponent implements OnInit {

  linkColor: any;
  tabs = ['First'];
  boxs = [];
  starLable: any;
  reasonLable: any;

  id;
  constructor(private cookiesService: CookiesService, private pulseService: PulseService, private _snackBar: MatSnackBar) {
    this.linkColor = localStorage.getItem('themeColor');
    this.id = this.cookiesService.getCookie('pulseId');
    this.getPulseRating(this.id);
  }

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.ratings.push({ reasons: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  ngOnInit() {

  }

  pulse;
  orgId;
  createdBy;
  logoUrl;
  i = 1; // rating value
  pulse1: any;

  setColor(newColor) {
    // console.log('value', newColor);
    // this.linkColor = newColor;
    // this.linkColor = this.cookiesService.getCookie('brandColor');
    this.linkColor = localStorage.getItem('themeColor');
  }

  setButtonStyle() {
    const styles = {
      // 'border-color': this.linkColor,
      // 'color': this.linkColor
      'border-color': localStorage.getItem('themeColor'),
      'color': localStorage.getItem('themeColor')
    };
    return styles;
  }

  pulseConfigDetail: any;
  ratings: any;
  // GET pulse configuration details by pulse configuration id
  getPulseRating(id) {
    this.pulseService.getPulseRatingConfig(id)
      .subscribe(
        res => {
          console.log('Response: ', res);
          this.pulseConfigDetail = res;
          this.pulse1 = res.responseData;
          this.ratings = res.responseData.ratings;
          // this.getStoreSpaceByPulse(this.id);
        },
        error => {
          console.log(error);
        }
      );
  }

  // For Future
  // qrValue: string;
  // StoreSpaceInfo: any = [];
  // encodedObj: any;
  // pulseMapOpbject: any;
  // getStoreSpaceByPulse(id) {
  //   this.pulseService.getStoreSpaceByPulse(id)
  //     .subscribe(res => {
  //       console.log('Store Space Info: ', res);
  //       this.StoreSpaceInfo = res.responseData[0];

  // this.pulseMapOpbject = 'orgId=' + this.orgId + '&storeCode=' + this.storeId + '&spaceCode=' + this.spaceId
  // this.encodedObj = localStorage.getItem('pulseUrl');
  //       this.qrValue = 'http://pulse.idc.tarento.com/rating/' + btoa(this.encodedObj);
  //     }, error => {
  //       console.log('error message: ', error);
  //     });
  // }

}
