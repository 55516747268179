import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { StoreService } from 'src/app/services/store/store.service';
import { CookiesService } from 'src/app/services/cookies/cookies.service';

@Component({
  selector: 'app-store-mapping',
  templateUrl: './store-mapping.component.html',
  styleUrls: ['./store-mapping.component.scss']
})
export class StoreMappingComponent implements OnInit {


  // @Input() shareOrgIdWithMap: string;

  drag = false;
  i: any;
  indexName = 'Select a store';
  constructor(private storeService: StoreService, private cookiesService: CookiesService) { }

  storeControl = new FormControl('', [Validators.required]);

  shareOrgIdWithMap: string;
  ngOnInit() {
    this.shareOrgIdWithMap = this.cookiesService.getCookie('orgId');
    this.getStoreListByOrgId(this.shareOrgIdWithMap);   // load country list in mapping component
    // this.unmappedSpace = [];
    this.getSpaceListByOrgId(this.shareOrgIdWithMap);
  }

  storeList = [];
  storeCodeList = [];
  // GET StoreList by passing orgId
  getStoreListByOrgId(orgId) {
    this.storeList = [];
    this.storeCodeList = [];
    this.storeService.getStoreListByParam(orgId).subscribe(res => {
      for (const data of res.responseData) {
        this.storeList.push(data.storeName);
        this.storeCodeList.push(data.id); // check response format
      }
      console.log('Store list ', res);
    }), // check api call whether it's a right calll
      error => {
        console.log(error);
      }
  }

  unmappedSpace = [];
  // GET unammped SpaceList by passing orgId
  getSpaceListByOrgId(orgId) {
    this.unmappedSpace = [];
    this.storeService.getSpaceListByOrgId(orgId).subscribe(res => {
      this.unmappedSpace = res.responseData;
      console.log('unmapped space: ', this.unmappedSpace);
    }, error => {
      console.log(error);
    });
  }

  selectedStoreCode: string;
  mappedSpace = [];
  // GET Space list by passing storeCode and orgId
  getSpaceListByStoreCodeAndOrgId(storeId, orgId) {
    this.mappedSpace = [];
    this.storeService.getSpaceListByStoreCodeAndOrgId(storeId, orgId)
      .subscribe(res => {
        this.mappedSpace = res.responseData;
        console.log('this.mappedSpace', this.mappedSpace);
      },
        err => {
          console.log(err);
        }
      );
  }

  // POST request - map space to store
  mapSpaceToStore(storeId, stores, orgId) {
    return this.storeService.mapSpaceToStore(storeId, stores, orgId)
      .subscribe(
        res => {
          if (res.statusInfo.statusCode === 200) {
            console.log('I am checking condition');
            this.getSpaceListByOrgId(this.shareOrgIdWithMap);
            this.getSpaceListByStoreCodeAndOrgId(this.selectedStoreCode, this.shareOrgIdWithMap);
          }
        },
        err => {
          console.log(err);
        });
  }

  // POST request -  unmap space from store
  unmapSpaceFromStore(storeCode, stores, orgId) {
    this.storeService.unmapSpaceFromStore(storeCode, stores, orgId)
      .subscribe(res => {
        console.log('response of unmap area from country api: ', res);
        if (res.statusInfo.statusCode === 200) {
          this.getSpaceListByStoreCodeAndOrgId(this.selectedStoreCode, this.shareOrgIdWithMap);
          this.getSpaceListByOrgId(this.shareOrgIdWithMap);
        }
      },
        error => {
          console.log('error message: ', error);
        }
      )
  }

  disabled: boolean = true;
  onClickStore(index) {
    this.disabled = false;
    this.indexName = 'Select a space';
    this.i = index;
    this.selectedStoreCode = this.storeCodeList[index];
    this.indexName = this.storeList[index];
    this.mappedSpace = [];
    this.getSpaceListByStoreCodeAndOrgId(this.selectedStoreCode, this.shareOrgIdWithMap);
    this.unmappedSpace = [];
    this.getSpaceListByOrgId(this.shareOrgIdWithMap);
  }

  // tslint:disable-next-line: member-ordering
  spaces;
  unmapClick(spaceId) {
    console.log('unmapped click space id: ', spaceId);
    this.spaces = [];
    this.spaces.push({ id: spaceId });
    this.unmapSpaceFromStore(this.selectedStoreCode, this.spaces, this.shareOrgIdWithMap);
  }

  tempObj1;
  spaces1;
  mapClick(spaceId) {
    console.log('map click space id:', spaceId);
    this.tempObj1 = [];
    this.tempObj1 = this.mappedSpace;
    this.spaces1 = [];
    for (var id1 of this.tempObj1) {
      this.spaces1.push({ id: id1.id });
    }
    this.spaces1.push({ id: spaceId });
    // console.log('spaces', this.spaces);
    this.mapSpaceToStore(this.selectedStoreCode, this.spaces1, this.shareOrgIdWithMap);
  }

}
