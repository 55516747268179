import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { RoleService } from 'src/app/services/role/role.service';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private router: Router,
    private roleService: RoleService, private cookiesService: CookiesService) {
    this.getRoleListByOrgId();
  }
  ngOnInit() {
  }
  searchText: any;
  roles = [];
  getRoleListByOrgId() {
    this.roleService.getRoleListByOrgId(this.cookiesService.getCookie('orgId'))
      .subscribe(
        res => {
          this.roles = res;
          console.log('role list: ', this.roles);
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }

  onDelete(role) {
    console.log('clicked role: ', role);
    this.deleteRoleById(role.id);
  }

  deleteRoleById(data) {
    this.roleService.deleteRole(data)
      .subscribe(
        res => {
          console.log('res of delete api: ', res);
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }

  goToAddRole() {
    this.router.navigate(['/dashboard/addRole']);
  }

  goToMapRoleAction() {
    this.router.navigate(['/dashboard/mapRoleAction']);
  }

  onSelectRole(role) {
    localStorage.setItem('roleId', role.id);
    localStorage.setItem('roleName', role.name);
    localStorage.setItem('roleDescription', role.description);
    // this.cookiesService.putCookie('roles', JSON.stringify(role));
    this.router.navigate(['/dashboard/usersByRole']);
  }

}
