import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { AreaService } from 'src/app/services/area/area.service';
import { Router } from '@angular/router';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UserService } from 'src/app/services/user/user.service';
import { RoleService } from 'src/app/services/role/role.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-area-detail',
  templateUrl: './area-detail.component.html',
  styleUrls: ['./area-detail.component.scss']
})
export class AreaDetailComponent implements OnInit {

  addAreaForm: FormGroup;
  submitted: boolean = false;

  orgId: any;
  ngOnInit() {
  }

  areaId: any;
  constructor(private formBuilder: FormBuilder, private areaService: AreaService, private router: Router,
    private cookiesService: CookiesService,
    private matSnackBar: MatSnackBar) {
    this.orgId = this.cookiesService.getCookie('orgId');
    this.areaId = this.cookiesService.getCookie('areaId');
    this.getSalesAreaById(this.areaId, this.orgId);
  }

  updateArea(name, code, description, isActive  ) {
    console.log(name, code, description, isActive);
    const data = {
      id: this.area.id,
      name: name,
      code: code,
      description: description,
      isActive: +JSON.parse(isActive),
      orgId:+this.orgId
    }
    this.areaService.addSalesArea(data).subscribe(
      response => {
        console.log('response in adding Area ', response);
        if (response.statusInfo.statusCode === 200) {
          this.router.navigate(['/dashboard/salesArea']);
        }
      },
      error => {
        console.log("error message: ", error);
      });
  }
  updateSalesArea(data) {
    this.areaService.updateSalesArea(data)
      .subscribe(
        res => {
          if (res.statusInfo.statusCode === 200) {
            this.matSnackBar.open('Sales area info has been updated', 'SUCCESS', { duration: 3000 });
          }
        }
      );
  }

  area: any;
  getSalesAreaById(id, orgId) {
    id = +id;
    orgId = + orgId;
    this.areaService.getSalesAreaByAreaId(id, orgId)
      .subscribe(
        res => {
          this.area = res.responseData[0];
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }

}
