import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import * as $ from 'jquery';
import { RAIN } from 'src/app/app-constants';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { CookiesService } from 'src/app/services/cookies/cookies.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  visible: boolean = true;
  organizationId;
  constructor(private route: ActivatedRoute, private cookiesService: CookiesService) {
    // this.route.paramMap.subscribe((params: ParamMap) => {
    // let id = parseInt(params.get('orgId'));
    // this.organizationId = id;
    // });
    this.organizationId = this.cookiesService.getCookie('orgId');
  }

  ngOnInit() {
    $('#myDIV .navbar-nav a').on('click', function () {
      $('#myDIV .navbar-nav').find('li.active').removeClass('active');
      $(this).parent('li').addClass('active');
    });
  }

}
