import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AreaService } from 'src/app/services/area/area.service';
import { CookiesService } from 'src/app/services/cookies/cookies.service';

@Component({
  selector: 'app-area-mapping',
  templateUrl: './area-mapping.component.html',
  styleUrls: ['./area-mapping.component.scss']
})
export class AreaMappingComponent implements OnInit {


  areaControl = new FormControl('', [Validators.required]);
  indexName1 = 'select an area';
  i: any;
  indexName = 'Select area';
  stores = [];

  constructor(private areaService: AreaService, private cookiesService: CookiesService) { }
  shareOrgIdWithMap: string;
  ngOnInit() {
    this.shareOrgIdWithMap = this.cookiesService.getCookie('orgId');
    this.getSalesAreaListByOrgId(this.shareOrgIdWithMap);
    this.getStoreListByOrgId(this.shareOrgIdWithMap);
  }


  areaList = [];
  areaCodeList = [];
  // GET SalesArea List by passing orgId 
  getSalesAreaListByOrgId(orgId) {
    this.areaList = [];
    this.areaCodeList = [];
    this.areaService.getSalesAreaListByParam(orgId)
      .subscribe(
        res => {
          console.log('get Sales Area List by OrgId', res);
          for (const data of res.responseData) {
            this.areaList.push(data.name);
            this.areaCodeList.push(data.id);
          }
        },
        err => {
          console.log(err);
        }
      );
  }

  unmappedStores = []
  // GET Store List by orgId
  getStoreListByOrgId(orgId) {
    this.unmappedStores = [];
    this.areaService.getStoreByAreaIdAndOrgId(null, orgId)
      .subscribe(
        res => {
          this.unmappedStores = res.responseData;
          console.log('getStoreList : unmapped ', this.unmappedStores);
        },
        err => {
          console.log(err);
        }
      );
  }

  selectedAreaCode: string;
  mappedStores = [];
  // GET Store list by AreaId and OrgId
  getStoreListByAreaIdAndOrgId(areaId, orgId) {
    this.mappedStores = [];
    this.areaService.getStoreByAreaIdAndOrgId(areaId, orgId)
      .subscribe(
        res => {
          this.mappedStores = res.responseData;
          console.log('store list by areaId and orgId: ', res);
        },
        err => {
          console.log(err);
        });
  }

  mapStoreAndSalesArea(salesAreaId, stores, orgId) {
    this.areaService.mapStoreToArea(salesAreaId, stores, orgId)
      .subscribe(
        res => {
          console.log('response of map store to area API ', res);
          if (res.statusInfo.statusCode === 200) {
            this.getStoreListByAreaIdAndOrgId(this.selectedAreaCode, this.shareOrgIdWithMap);
            this.getStoreListByOrgId(this.shareOrgIdWithMap);
          }
        },
        err => {
          console.log(err);
        });
  }

  unmapStoreFromArea(salesAreaId, stores, orgId1) {
    console.log('unmapStoreFromArea payload ', salesAreaId, stores, orgId1);
    this.areaService.unmapStoreFromArea(salesAreaId, stores, orgId1)
      .subscribe(
        res => {
          console.log('response of unmapStoreFromArea API: ', res);
          if (res.statusInfo.statusCode === 200) {
            this.getStoreListByAreaIdAndOrgId(this.selectedAreaCode, this.shareOrgIdWithMap);
            this.getStoreListByOrgId(this.shareOrgIdWithMap);
          }
        },
        err => {
          console.log('error: ', err);
        }
      )
  }

  disabled = true;
  onClickArea(index) {
    this.disabled = false;
    this.indexName = 'Select an area';
    this.i = index;
    this.selectedAreaCode = this.areaCodeList[index];
    this.indexName = this.areaList[index];
    this.getStoreListByAreaIdAndOrgId(this.selectedAreaCode, this.shareOrgIdWithMap);
    this.getStoreListByOrgId(this.shareOrgIdWithMap);
  }

  tempObj1 = [];
  unmapClick(storeId) {
    console.log('unmapped click store id: ', storeId, this.shareOrgIdWithMap);
    this.stores = [];
    this.stores.push({ id: storeId });
    this.unmapStoreFromArea(this.selectedAreaCode, this.stores, this.shareOrgIdWithMap);
  }

  mapClick(storeId) {
    // console.log('map click store id: ', storeId);
    this.tempObj1 = [];
    this.tempObj1 = this.mappedStores;
    this.stores = [];
    for (var id1 of this.tempObj1) {
      this.stores.push({ id: id1.id });
    }
    this.stores.push({ id: storeId });
    this.mapStoreAndSalesArea(this.selectedAreaCode, this.stores, this.shareOrgIdWithMap);
  }


}
