import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoleService } from 'src/app/services/role/role.service';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';


@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent implements OnInit {

  addRoleForm: FormGroup;
  submitted: boolean = false;

  constructor(private formBuilder: FormBuilder, private router: Router, private matSnack: MatSnackBar,
    private roleService: RoleService, private cookiesService: CookiesService) {
    // this.getRoleListByOrgId();
  }

  ngOnInit() {
    this.addRoleForm = this.formBuilder.group({
      roleName: ['', Validators.required],
      description: ['', Validators.required],
      isAdmin: [''],
      code: ['', Validators.required]
    });

  }

  get f() { return this.addRoleForm.controls; }

  onSubmit(f) {
    this.submitted = true;
    if (this.addRoleForm.valid) {
      this.addRole()
    }
    return;
  }

  addRole() {
    let data = {
      name: this.addRoleForm.value.roleName,
      description: this.addRoleForm.value.description,
      isAdmin: this.addRoleForm.value.isAdmin,
      createdBy: +this.cookiesService.getCookie('createdBy'),
      orgId: +this.cookiesService.getCookie('orgId'),
      code: this.addRoleForm.value.code
    };
    console.log(data, this.addRoleForm);
    this.roleService.addRole(data).subscribe(
      res => {
        console.log('res');
        if (res.statusInfo.statusCode === 200) {
          this.matSnack.open('new role added successfully!', 'SUCCESS', { duration: 2000 });
          this.router.navigate(['/dashboard/roleList']);
        }
      },
      error => {
        console.log('error message: ', error);
      }
    );
  }


}
