import { OnInit } from "@angular/core";
import { UserService } from "src/app/services/user/user.service";
import { CookiesService } from "src/app/services/cookies/cookies.service";
import { ChangeDetectionStrategy, Component } from '@angular/core';

import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
  ValidatorFn,
  NgForm
} from '@angular/forms';
import { of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.scss"]
})
export class UserListComponent implements OnInit {
  orgId;
  userInfo: boolean = false;
  searchText;
  checked: boolean = false;
  username;
  emailId;
  userId;
  phoneNo;

  constructor(private userService: UserService, private cookiesService: CookiesService,
    private formBuilder: FormBuilder, private _snackBar: MatSnackBar, private router: Router) {
  }


  roleForm: FormGroup;
  masterRoleForm: FormGroup;

  orgDomainId: any;
  orgDomain: any;
  ngOnInit() {
    this.orgId = this.cookiesService.getCookie('orgId');
    this.orgDomain = this.cookiesService.getCookie('orgDomain');
    if (this.orgDomain === 'RL') {
      this.orgDomainId = 2;
    }
    if (this.orgDomain === 'EN') {
      this.orgDomainId = 1;
    }
    this.getUserListByOrgId(this.orgId);
  }

  onCheckBoxSubmit(): void {
    console.log("role form data:", this.roleForm.value);
  }

  onSlideClick(event: MatSlideToggleChange, user) {
    let data = user;
    data['isActive'] = +event.checked;
    this.updateUserDetail(data);
  }

  updateUserDetail(data) {
    this.userService.addUser(data).subscribe(
      res => {
        if (res.statusInfo.statusCode === 200) {
          console.log('response: ', res);
          this._snackBar.open('user details is updated', 'SUCCESS', { duration: 2000 });
        }
      },
      error => {
        console.log('error message: ', error);
      }
    );
  }

  onClickAdd() {
    this.router.navigate(['dashboard/createUser']);
  }

  checkedItems = [];
  checkedIdList = [];
  updateRoles(checkedBox: NgForm) {
    // let 
    this.checkedItems = checkedBox.value;
    console.log('checked values: ', this.checkedItems);
    var roles = [];
    this.orgId = this.cookiesService.getCookie('orgId');
    this.checkedIdList = [];
    // tslint:disable-next-line: forin
    for (let i in this.roles) {
      if (this.checkedItems[this.roles[i].id] === true) {
        // this.checkedIdList.push(this.roles[i].id);
        this.checkedIdList.push({ id: this.roles[i].id });
      }
      i = i + 1;
    }
    this.assignRoleToUser(this.userId, this.checkedIdList, this.orgId);

  }

  tempUserId;  // used in getRoleList for a particular user
  onSubmit(user) {
    this.username = user.username;
    this.userId = "";
    this.userId = user.id;
    this.phoneNo = user.phoneNo;
    this.getRolesByOrgId(this.orgId);
    this.tempUserId = this.userId;
    this.userInfo = true;
  }

  users: any = [];
  // GET User list by OrgId
  getUserListByOrgId(orgId) {
    this.users = [];
    orgId = this.cookiesService.getCookie("orgId");
    this.userService.getUserListByOrgId(orgId).subscribe(
      res => {
        this.users = res;
        console.log(res)
      },
      error => {
        console.log(error);
      }
    );
  }

  assignRoleToUser(userId, roles, orgId) {
    orgId = this.cookiesService.getCookie("orgId");
    // roles.push()
    this.userService.assignRoleToUser(userId, roles, orgId)
      .subscribe(
        res => {
          if (res.statusInfo.statusCode === 200) {
            this._snackBar.open('role list is updated', 'success', {
              duration: 2000,
            });
          }
        },
        error => {
          console.log('error');
        }
      )

  }
  assignedRoles = [];
  assignedRolesData = [];
  tempRolesWithFlag: any;
  // GET role list for a particular user by passing user ID
  getRoleListByUserIdAndOrgId(userId, orgId) {
    this.assignedRoles = [];
    this.userService.getRoleListByUserIdAndOrgId(userId, orgId).subscribe(
      res => {
        // tslint:disable-next-line: forin
        for (let i in res.responseData.roles) {
          this.assignedRoles = res.responseData.roles[i].name;
          i = i + 1;
        }
        this.tempRolesWithFlag = this.rolesWithFlag;
        // tslint:disable-next-line: forin
        for (let j in res.responseData.roles) {
          // tslint:disable-next-line: forin
          for (let k in this.tempRolesWithFlag) {
            if (res.responseData.roles[j].id === this.tempRolesWithFlag[k].res[k].id) {
              this.tempRolesWithFlag[k].isSelected = true;
            }
            k = k + 1;
          }
          j = j + 1;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  roles = [];
  rolesName = [];
  rolesId = [];
  rolesWithFlag = [];
  // GET role list by passing orgId
  getRolesByOrgId(orgId) {
    this.roles = [];
    this.rolesWithFlag = [];
    this.userService.getRoleListByOrgId(orgId).subscribe(
      res => {
        this.roles = res;
        for (let i in res) {
          this.rolesWithFlag.push({ res, 'isSelected': false });
        }
        this.getRoleListByUserIdAndOrgId(this.tempUserId, this.orgId);
      },
      error => {
        console.log(error);
      }
    );
  }

  onDelete(user) {
    this.userInfo = false; // hide after click on delete
    console.log('delete user', user);
    user['orgId'] = +this.orgId;
    console.log('...', user);
    this.deleteUser(user);
  }

  deleteUser(data) {
    this.userService.deleteUser(data).subscribe(
      res => {
        console.log('delete api call response ', res);
        if (res.statusInfo.statusCode === 200) {
          this._snackBar.open('user is deleted successfully!', 'success', {
            duration: 2000,
          });
          this.getUserListByOrgId(this.orgId);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  masterRoles: any;
  getMasterRoles(orgDomainId) {
    this.userService.getMasterRoleByOrgDomain(orgDomainId)
      .subscribe(
        res => {
          console.log('master role: ', res);
          this.masterRoles = res.responseData;
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }

}
