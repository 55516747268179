import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-visualization-list',
  templateUrl: './visualization-list.component.html',
  styleUrls: ['./visualization-list.component.scss']
})
export class VisualizationListComponent implements OnInit {

  searchText;
  orgId: any;
  constructor(private router: Router, private dashboardService: DashboardService,
    private snackBar: MatSnackBar,
    private cookiesService: CookiesService) {
    this.orgId = this.cookiesService.getCookie('orgId');
    this.getAllVisualization();
  }

  ngOnInit() {
  }

  routerFlag: boolean = false;
  hideFlag: boolean = true;

  addVisualizationFlag() {
    this.hideFlag = false;
    this.routerFlag = true;
  }

  visualizations: any = [];
  getAllVisualization() {
    this.dashboardService.getAllVisualization(this.orgId)
      .subscribe(
        res => {
          this.visualizations = res;
          console.log('get visualization res ', res);
        },
        error => {
          console.log('error message: ', error);
        }
      )
  }

  dashboards: any = [];
  getDashboard() {
    this.dashboardService.getDashboards(this.orgId)
      .subscribe(
        res => {
          this.dashboards = res;
          console.log('Dashboards list ', res);
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }

  goToVisualization(visualization) {
    this.cookiesService.putCookie('visualizationId', visualization.id);
    this.router.navigate(['dashboard/visualizationDetail']);
  }

  onClickDelete(visualization) {
    this.deleteDashboard(visualization.id);
  }

  deleteDashboard(id) {
    this.dashboardService.deletevisualizationById(id)
      .subscribe(
        res => {
          if (res.statusInfo.statusCode === 200) {
            this.snackBar.open('dashboard deleted successfully!', 'SUCCESS', { duration: 2000 });
            this.getAllVisualization();
          }
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }

}
