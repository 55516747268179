import { Component, OnInit } from '@angular/core';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { StoreService } from 'src/app/services/store/store.service';
import { SpaceService } from 'src/app/services/space/space.service';
import { PulseService } from 'src/app/services/pulse/pulse.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { RAIN } from 'src/app/app-constants';

@Component({
  selector: 'app-pulse-config-map',
  templateUrl: './pulse-config-map.component.html',
  styleUrls: ['./pulse-config-map.component.scss']
})
export class PulseConfigMapComponent implements OnInit {
  constructor(private cookiesService: CookiesService, private storeService: StoreService,
    private spaceService: SpaceService, private pulseService: PulseService,
    private router: Router, private _snackBar: MatSnackBar, private sanitizer: DomSanitizer) {
    this.orgId = this.cookiesService.getCookie('orgId');
    this.getPulseRatingConfigurationList(this.orgId);
    this.getStoreListByOrgId(this.orgId);
  }

  orgId;
  storeDisabledFlag = true;
  spaceDisabledFlag = true;
  myFormControl = new FormControl();
  qrValue: string = 'tarento.com';
  ngOnInit() {
    this.orgId = this.cookiesService.getCookie('orgId');
  }

  // for hiding pulse preview
  onClickSelect() {
    this.pulseFlag = false;
  }
  imageUrl;
  getImage(): void {
    const canvas = document.querySelector("canvas") as HTMLCanvasElement;
    let imageData = canvas.toDataURL("image/jpeg").toString();
    this.imageUrl = imageData;
  }

  clickDownload(data) {
    console.log(data);
  }

  pulseFlag = false;
  // visible pulse configuration view
  onClickOption(pulseId) {
    this.cookiesService.putCookie('pulseId', pulseId);
    this.pulseId = pulseId;
    this.storeDisabledFlag = false;
    this.pulseFlag = true;
  }

  // 
  onClickStore(storeId) {
    console.log('selected store id ', storeId);
    this.storeId = storeId;
    this.getSpaceListByStoreCodeAndOrgId(storeId, this.orgId);
    this.spaceDisabledFlag = false;
  }

  mapButtonDisabledFlag = true;
  spaceOnClick(spaceId) {
    this.spaceId = spaceId;
    this.mapButtonDisabledFlag = false;
  }

  pulseId;
  storeId;
  spaceId;
  encodedObj;
  onClickMap() {
    let mapPulseObj =
    {
      "pulseId": this.pulseId,
      "storeId": this.storeId,
      "spaceId": this.spaceId
    }
    console.log('map button clicked,  data: ', mapPulseObj);
    localStorage.setItem('pulseUrl', 'orgId=' + this.orgId + '&storeCode=' + this.storeId + '&spaceCode=' + this.spaceId);
    this.encodedObj = localStorage.getItem('pulseUrl');
    localStorage.setItem('encodedPulseUrl', btoa(this.encodedObj));
    this.mapPulseConfiguration(mapPulseObj);

  }

  pulses: any = [];
  // GET pulse rating configuratiion list
  getPulseRatingConfigurationList(orgId) {
    this.pulses = [];
    this.pulseService.getPulseRatingConfigList(orgId)
      .subscribe(
        res => {
          console.log('getPulseRatingConfigList', res);
          this.pulses = res;
        },
        error => { console.log(error); }
      );
  }

  stores: any = [];
  // GET StoreList by passing orgId
  getStoreListByOrgId(orgId) {
    this.stores = [];
    this.storeService.getStoreListByParam(orgId).subscribe(
      res => {
        console.log('Store list ', res);
        this.stores = res;
      }),
      error => {
        console.log(error);
      }
  }

  mappedSpace: any = [];
  selectBlankMessage: string;
  flagForNoValues = false; // warning message
  // GET Space list by passing storeCode and orgId
  getSpaceListByStoreCodeAndOrgId(storeId, orgId) {
    this.mappedSpace = [];
    this.storeService.getSpaceListByStoreCodeAndOrgId(storeId, orgId)
      .subscribe(res => {
        this.mappedSpace = res;
        if (res.responseData.length === 0) {
          this.selectBlankMessage = 'no space is mapped with this store';
          this.flagForNoValues = true;
        }
      },
        err => {
          console.log(err);
        }
      );
  }

  // map pulse configuration with stor and space
  mapPulseObj: any;
  successFlag: boolean = false;
  mapPulseConfiguration(mapPulseObj) {
    this.pulseService.mapPulseConfigurationWithStoreAndSpace(mapPulseObj)
      .subscribe(
        res => {
          console.log('pulse configuration mapping response ', res);
          if (res.statusInfo.statusCode === 200) {
            this._snackBar.open('Pulse configuration mapped with space and store', 'SUCCESS', { duration: 3000 });
            this.router.navigate(['/dashboard/pulseConfigList']);
            // this._snackBar.open('' + btoa(this.encodedObj) + ' ', 'MAPPED', { duration: 30000 });
            // this.qrValue = RAIN.PULSE_FEEDBACK_DOMAIN + RAIN.RATING + btoa(this.encodedObj);
            // this.successFlag = true;
          }
        },
        error => {
          console.log(error);
        });
  }
}
