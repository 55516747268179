import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { CountryService } from 'src/app/services/country/country.service';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { map, startWith } from 'rxjs/operators';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user/user.service';
import { RoleService } from 'src/app/services/role/role.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-country-detail',
  templateUrl: './country-detail.component.html',
  styleUrls: ['./country-detail.component.scss']
})
export class CountryDetailComponent implements OnInit {

  countryForm: FormGroup;
  orgId: any;

  ngOnInit() {
  }
  onUpdate(name, code, currency, phoneCode) {

  }
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  fruits: string[] = [];
  // tslint:disable-next-line: member-ordering
  allFruits: string[] = [];

  @ViewChild('fruitInput', { static: false }) fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  countryId;
  constructor(private countryService: CountryService, private matSnackBar: MatSnackBar,
    private roleService: RoleService, private cookiesService: CookiesService, private userService: UserService) {
    this.orgId = this.cookiesService.getCookie('orgId');
    this.countryId = this.cookiesService.getCookie('countryId');
    this.getUsersByMasterRole();
    this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => fruit ? this._filter(fruit) : this.allFruits.slice()));
  }

  add(event: MatChipInputEvent): void {
    // Add fruit only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our fruit
      if ((value || '').trim()) {
        this.fruits.push(value.trim());
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.fruitCtrl.setValue(null);
    }
  }

  remove(fruit: string): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fruits.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allFruits.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }

  assignCountryManager() {

  }

  userList: any = [];
  getUsersByMasterRole() {
    let data = {
      'orgId': +this.orgId,
      'masterRoleId': 1
    }
    this.userService.getUsersByMasterRole(data)
      .subscribe(
        res => {
          console.log('res ', res);
          // tslint:disable-next-line: forin
          for (var i in res.responseData) {
            this.allFruits.push(res.responseData[i].userName);
          }
          console.log('-res ', this.allFruits);
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }

  mapUserToCountryRole() {
    let data =
    {
      'orgId': +this.orgId,
      'countryId': +this.countryId,
      'userId': '',
      'masterRoleId': 1
    };
    this.roleService.mapUserMasterRoleCountryOrg(data)
      .subscribe(res => {
        if (res.statusInfo.statusCode === 200) {
          this.matSnackBar.open('Country manager role assign to user', 'SUCCESS', { duration: 2000 });
        }
      }, error => {
        console.log('error message: ', error);
      }
      );
  }

}
