import { Injectable } from '@angular/core';
import { RAIN } from '../../app-constants';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { ErrorService } from '../error/error.service';
import { CookiesService } from '../cookies/cookies.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private GET_ALL_VISUALIZATION = RAIN.BASE + RAIN.DASHBOARD + RAIN.APIS.GET_ALL_VISUALIZATIONS;
  // private GET_ALL_VISUALIZATION = 'http://localhost:8089/' + RAIN.DASHBOARD + RAIN.APIS.GET_ALL_VISUALIZATIONS;
  private GET_ALL_DASHBOARD_URL = RAIN.BASE + RAIN.DASHBOARD + RAIN.APIS.GET_ALL_DASHBOARD;  // need to update
  // private GET_VISUALIZATION_BY_ID_URL = RAIN.BASE9 + RAIN.DASHBOARD + RAIN.APIS.GET_VISUALIZATION;
  // private ADD_VISUALIZATION_URL = RAIN.BASE9 + RAIN.DASHBOARD + RAIN.APIS.ADD_NEW_VISUALIZATION;
  private ADD_VISUALIZATION_URL = RAIN.BASE + RAIN.DASHBOARD + RAIN.APIS.ADD_NEW_VISUALIZATION;
  private GET_VISUALIZATION_BY_ID_URL = RAIN.BASE + RAIN.DASHBOARD + RAIN.APIS.GET_VISUALIZATION;

  private GET_ROLE_LIST_BY_VISUALIZATION_ID_URL = RAIN.BASE + RAIN.DASHBOARD + RAIN.APIS.GET_ROLE_BY_VISUALIZATION;
  private GET_ROLE_LIST_BY_DASHBOARD_ID_URL = RAIN.BASE + RAIN.DASHBOARD + RAIN.APIS.GET_ROLE_BY_DASHBOARD;

  private GET_CHART_LIST_BY_VISUALIZATION_ID_URL = RAIN.BASE + RAIN.DASHBOARD + RAIN.APIS.GET_CHART_BY_VISUALIZATION;
  private GET_UNMAPPED_CHART_LIST_FOR_VISUALIZATION = RAIN.BASE + RAIN.DASHBOARD +
    RAIN.APIS.GET_UNMAPPED_CHART_TO_VISUALIZATION;
  private MAP_CHART_TO_VISUALIZATION_URL = RAIN.BASE + RAIN.DASHBOARD + RAIN.APIS.MAP_CHART_TO_VISUALIZATION;
  private UNMAP_CHART_TO_VISUALIZATION_URL = RAIN.BASE + RAIN.DASHBOARD + RAIN.APIS.UNMAP_CHART_TO_VISUALIZATION;
  private MAP_ROLE_TO_VISUALIZATION_URL = RAIN.BASE + RAIN.DASHBOARD + RAIN.APIS.MAP_ROLE_TO_VISUALIZATION;
  private ASSIGN_ROLE_TO_DASHBOARD_URL = RAIN.BASE + RAIN.ROLE; // need to update

  private MAP_ROLE_TO_DASHBOARD = RAIN.BASE + RAIN.DASHBOARD + RAIN.APIS.MAP_ROLE_TO_DASHBOARD;
  private MAP_VISUALIZATION_TO_DASHBOARD = RAIN.BASE + RAIN.DASHBOARD + RAIN.APIS.MAP_VISUALIZATION_TO_DASHBOARD;
  private UNMAP_VISUALIZATION_TO_DASHBOARD = RAIN.BASE + RAIN.DASHBOARD + RAIN.APIS.UNMAP_VISUALIZATION_FROM_DASHBOARD;
  private ADD_NEW_DASHBOARD_URL = RAIN.BASE + RAIN.DASHBOARD + RAIN.APIS.ADD_NEW_DASHBOARD;

  private GET_MAPPED_VISUALIZATIONS_BY_DASHBOARD_ID_URL = RAIN.BASE + RAIN.DASHBOARD + RAIN.APIS.GET_MAPPED_VISUALIZATION;
  private GET_UNMAPPED_VISUALIZATIONS_BY_DASJBOARD_ID_URL = RAIN.BASE + RAIN.DASHBOARD +
    RAIN.APIS.GET_UNMAPPED_VISUALIZATION;
  private GET_DASHBOARD_DETAILS_BY_DASHBOARD_ID_URL = RAIN.BASE + RAIN.DASHBOARD + RAIN.APIS.GET_DASHBOARD_DETAIL;
  private DELETE_DASHBOARD_BY_ID = RAIN.BASE + RAIN.DASHBOARD + RAIN.APIS.DELETE_DASHBOARD;
  private DELETE_VISUALIZATION_BY_ID = RAIN.BASE + RAIN.DASHBOARD + RAIN.APIS.DELETE_VISUALIZATION;
  private UPDATE_DASHBOARD_DETAILS = RAIN.BASE + RAIN.DASHBOARD + RAIN.APIS.UPDATE_DASHBOARD;
  private UPDATE_VISUALIZATION_DETAILS = RAIN.BASE + RAIN.DASHBOARD + RAIN.APIS.UPDATE_VISUALIZATION;

  constructor(private httpClient: HttpClient, private errorHandler: ErrorService, private cookiesService: CookiesService) { }

  getAllVisualization(orgId) {
    orgId = +orgId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      }),
      params: new HttpParams().set('orgId', orgId)
    };
    return this.httpClient.get<any>(this.GET_ALL_VISUALIZATION, httpOptions)
      .pipe(catchError(this.errorHandler.handleError)
      );
  }
  getDashboards(orgId) {
    orgId = +orgId;
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: new HttpParams().set('orgId', orgId)
    }
    return this.httpClient.get<any>(this.GET_ALL_DASHBOARD_URL, httpOptions)
      .pipe(catchError(this.errorHandler.handleError)
      );
  }

  addNewVisualization(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      }),
    };
    return this.httpClient.post<any>(this.ADD_VISUALIZATION_URL, data, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }

  getVisualizationById(id) {
    id = +id;
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: new HttpParams().set('id', id)
    };
    return this.httpClient.get<any>(this.GET_VISUALIZATION_BY_ID_URL, httpOptions)
      .pipe(catchError(this.errorHandler.handleError)
      );
  }


  getRoleByVisualizationId(visualization_id, orgId) {
    visualization_id = +visualization_id;
    orgId = +orgId;
    let params = new HttpParams();
    params = params.append('visualization_id', visualization_id);
    params = params.append('orgId', orgId);
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: params
    };
    return this.httpClient.get<any>(this.GET_ROLE_LIST_BY_VISUALIZATION_ID_URL, httpOptions)
      .pipe(catchError(this.errorHandler.handleError)
      );
  }

  getRoleByDashboardId(dashboard_id, orgId) {
    dashboard_id = +dashboard_id;
    orgId = +orgId;
    let params = new HttpParams();
    params = params.append('dashboard_id', dashboard_id);
    params = params.append('orgId', orgId);
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: params
    };
    return this.httpClient.get<any>(this.GET_ROLE_LIST_BY_DASHBOARD_ID_URL, httpOptions)
      .pipe(catchError(this.errorHandler.handleError)
      );
  }

  getChartByVisualizationId(visualization_id, orgId) {
    orgId = +orgId;
    visualization_id = +visualization_id;
    let params = new HttpParams();
    params = params.append('visualization_id', visualization_id);
    params = params.append('orgId', orgId);
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: params
    };
    return this.httpClient.get<any>(this.GET_CHART_LIST_BY_VISUALIZATION_ID_URL, httpOptions)
      .pipe(catchError(this.errorHandler.handleError)
      );
  }

  getUnmappedChartToVisualization(visualization_id, orgId) {
    visualization_id = +visualization_id;
    orgId = +orgId;
    let params = new HttpParams();
    params = params.append('visualization_id', visualization_id);
    params = params.append('orgId', orgId);
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: params
    };
    return this.httpClient.get<any>(this.GET_UNMAPPED_CHART_LIST_FOR_VISUALIZATION, httpOptions)
      .pipe(catchError(this.errorHandler.handleError)
      );
  }

  mapChartToVisualization(id, chartId, orgId) {
    id = +id;
    chartId = +chartId;
    orgId = +orgId;
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    };
    return this.httpClient.post<any>(this.MAP_CHART_TO_VISUALIZATION_URL, { id, chartId, orgId }, httpOptions);
  }

  unmapChartToVisualization(id, chartId, orgId) {
    id = +id;
    chartId = +chartId;
    orgId = +orgId;
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-TYpe': 'application/json',
          Accept: 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    };
    return this.httpClient.post<any>(this.UNMAP_CHART_TO_VISUALIZATION_URL, { id, chartId, orgId }, httpOptions);
  }

  mapRoleToVisualization(visualizationId, roles, orgId) {
    orgId = +orgId;
    visualizationId = +visualizationId;

    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        })
    };
    console.log('send data: ', visualizationId, roles, orgId);
    return this.httpClient.post<any>(this.MAP_ROLE_TO_VISUALIZATION_URL, { visualizationId, roles, orgId }, httpOptions);
  }

  mapRoleToDashboard(dashboardId, roles, orgId) {
    dashboardId = +dashboardId;
    orgId = +orgId;
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    };
    return this.httpClient.post<any>(this.MAP_ROLE_TO_DASHBOARD, { dashboardId, roles, orgId }, httpOptions);
  }

  mapVisualizationToDashboard(dashboardId, visualizationId, orgId) {
    orgId = +orgId;
    dashboardId = +dashboardId;
    visualizationId = +visualizationId;
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    };
    return this.httpClient.post<any>(this.MAP_VISUALIZATION_TO_DASHBOARD, { dashboardId, visualizationId, orgId }, httpOptions);
  }

  unmapVisualizationToDashboard(dashboardId, visualizationId, orgId) {
    orgId = +orgId;
    dashboardId = +dashboardId;
    visualizationId = +visualizationId;
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    };
    return this.httpClient.post<any>(this.UNMAP_VISUALIZATION_TO_DASHBOARD, { dashboardId, visualizationId, orgId }, httpOptions);
  }

  addNewDashboard(data) {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    };
    return this.httpClient.post<any>(this.ADD_NEW_DASHBOARD_URL, data, httpOptions);
  }

  getMappedVisualizationByDashboardId(dashboard_id, orgId) {
    dashboard_id = +dashboard_id;
    orgId = +orgId;
    let params = new HttpParams();
    params = params.append('dashboard_id', dashboard_id);
    params = params.append('orgId', orgId);
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: params
    };
    return this.httpClient.get<any>(this.GET_MAPPED_VISUALIZATIONS_BY_DASHBOARD_ID_URL, httpOptions);
  }

  getUnmappedVisualizationByDashboardId(dashboard_id, orgId) {
    dashboard_id = +dashboard_id;
    orgId = +orgId;
    let params = new HttpParams();
    params = params.append('dashboard_id', dashboard_id);
    params = params.append('orgId', orgId);
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: params
    };
    return this.httpClient.get<any>(this.GET_UNMAPPED_VISUALIZATIONS_BY_DASJBOARD_ID_URL, httpOptions);
  }

  getDashboardByID(id) {
    id = +id;
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: new HttpParams().set('id', id)
    };
    return this.httpClient.get<any>(this.GET_DASHBOARD_DETAILS_BY_DASHBOARD_ID_URL, httpOptions);
  }

  deleteDashboardById(id) {
    id = +id;
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: new HttpParams().set('id', id)
    }
    return this.httpClient.delete<any>(this.DELETE_DASHBOARD_BY_ID, httpOptions);
  }

  deletevisualizationById(id) {
    id = +id;
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: new HttpParams().set('id', id)
    };
    return this.httpClient.delete<any>(this.DELETE_VISUALIZATION_BY_ID, httpOptions);
  }

  updateDashboardDetails(data) {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    };
    return this.httpClient.put<any>(this.UPDATE_DASHBOARD_DETAILS, data, httpOptions);
  }

  updateVisualization(data) {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    };
    return this.httpClient.put<any>(this.UPDATE_VISUALIZATION_DETAILS, data, httpOptions);
  }

}
