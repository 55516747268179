import { Injectable } from '@angular/core';
import { CookiesService } from '../cookies/cookies.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../error/error.service';
import { RAIN } from '../../app-constants';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  orgId: any;
  private GET_CHART_BY_ID_URL = RAIN.BASE + RAIN.META + RAIN.APIS.GET_CHART;
  private GET_ACTIVE_CHART_LIST_URL = RAIN.BASE + RAIN.META + RAIN.APIS.GET_ACTIVE_CHART;
  private GET_CHART_LIST_URL = RAIN.BASE + RAIN.META + RAIN.APIS.GET_ALL_CHART;

  private ADD_CHART_URL = RAIN.BASE + RAIN.META + RAIN.APIS.ADD_CHART;
  private GET_SERVICE_API_BY_ID_URL = RAIN.BASE + RAIN.META + RAIN.APIS.GET_SERVICE_API;
  private GET_SERVICE_API_LIST_URL = RAIN.BASE + RAIN.META + RAIN.APIS.GET_ALL_SERVICE_API;
  private DELETE_CHART_BY_ID_URL = RAIN.BASE + RAIN.META + RAIN.APIS.DELETE_CHART;
  private UPDATE_CHART_BY_ID_URL = RAIN.BASE + RAIN.META + RAIN.APIS.UPDATE_CHART;
  private GET_CHART_TYPE_BY_API = RAIN.BASE + RAIN.MASTER + RAIN.APIS.GET_CHART_TYPE_BY_APIS;

  constructor(private cookiesService: CookiesService, private httpClient: HttpClient, private errorHandler: ErrorService) {
    this.cookiesService.getCookie('orgId');
  }

  getChartById(id, orgId) {
    id = +id;
    orgId = +orgId;
    let params = new HttpParams();
    params = params.append('org_id', orgId);
    params = params.append('id', id);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      }),
      params: params
    };
    return this.httpClient.get<any>(this.GET_CHART_BY_ID_URL, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }

  getActiveChartList(orgId) {
    orgId = +orgId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      }),
      params: new HttpParams().set('orgId', orgId)
    };
    return this.httpClient.get<any>(this.GET_ACTIVE_CHART_LIST_URL, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }

  getChartList(orgId) {
    orgId = +orgId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      }),
      params: new HttpParams().set('org_id', orgId)
    };
    return this.httpClient.get<any>(this.GET_CHART_LIST_URL, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }

  addNewChart(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      }),
    };
    return this.httpClient.post<any>(this.ADD_CHART_URL, data, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }

  getServiceApiById(id) {
    id = +id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      }),
      params: new HttpParams().set('id', id)
    };
    return this.httpClient.get<any>(this.GET_SERVICE_API_BY_ID_URL, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }

  getServiceApiList() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      }),
    };
    return this.httpClient.get<any>(this.GET_SERVICE_API_LIST_URL, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }

  deleteChartById(id) {
    id = +id;
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: new HttpParams().set('id', id)
    };
    return this.httpClient.delete<any>(this.DELETE_CHART_BY_ID_URL, httpOptions);
  }

  updateChartDetails(data) {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      )
    };
    return this.httpClient.put<any>(this.UPDATE_CHART_BY_ID_URL, data, httpOptions);
  }

  getChartTypeByApi(apiId) {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: new HttpParams().set('id', apiId)
    };
    return this.httpClient.get<any>(this.GET_CHART_TYPE_BY_API, httpOptions);
  }
}
