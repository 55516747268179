import { Component, OnInit } from '@angular/core';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { SpaceService } from 'src/app/services/space/space.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-space',
  templateUrl: './space.component.html',
  styleUrls: ['./space.component.scss']
})
export class SpaceComponent implements OnInit {

  orgId;
  constructor(private cookiesService: CookiesService, private spaceService: SpaceService, private router: Router,
    private _snackBar: MatSnackBar) {
    this.orgId = this.cookiesService.getCookie('orgId');
    this.getAllSpaceListByOrgId(this.orgId);
  }

  ngOnInit() {
  }

  spaceList;
  // GET unammped SpaceList by passing orgId
  getAllSpaceListByOrgId(orgId) {
    this.spaceService.getAllSpaceListByOrgId(orgId).subscribe(res => {  // update the url - it's giving unmapped space list
      this.spaceList = res;
      console.log("space ", this.spaceList);
    }, error => {
      console.log(error);
    });
  }

  onClickEdit(space) {
    this.cookiesService.putCookie('spaceId', space.id);
    this.router.navigate(['/dashboard/spaceDetail']);
  }

  spaces;
  // GET unammped SpaceList by passing orgId
  getSpaceListByOrgId(orgId) {
    this.spaceService.getSpaceListByOrgId(orgId).subscribe(res => {  // update the url - it's giving unmapped space list
      this.spaces = res;
      console.log("space ", this.spaces);
    }, error => {
      console.log(error);
    });
  }

  routerFlag = false;
  hideFlag: boolean = true;
  mapSpaceFlag() {
    this.hideFlag = false;
    this.routerFlag = true;
  }

  addSpaceFlag() {
    this.hideFlag = false;
    this.routerFlag = true;
  }
  onDelete(space) {
    console.log('delete space data', space);
    this.spaceList['orgId'] = +this.orgId;
    console.log('...', space);
    this.deleteSpace(space);
  }

  deleteSpace(space) {
    this.spaceService.deleteSpace(space).subscribe(
      res => {
        console.log('delete api call response ', res);
        if (res.statusInfo.statusCode === 200) {
          this._snackBar.open('country is deleted successfully!', 'success', {
            duration: 2000,
          });
          this.getSpaceListByOrgId(this.orgId);
        }
      },
      err => {
        console.log(err);
      }
    );
  }


}
