import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  constructor(private _cookieService: CookieService) { }


  // put cookies with expiry time

  // putMainToken(key: string, value: string) {
  //   const expiryDate : Date = new Date(new Date().getTime() + (1000 * 60 * 20));
  //   return this._cookieService.put(key, value, {"expires" : expiryDate});
  // }

  putCookie(key: string, value: string) {
    return this._cookieService.put(key, value);
  }

  getCookie(key: string) {
    return this._cookieService.get(key);
  }

  clear(): void {
    // localStorage.clear(); 
    return this._cookieService.removeAll();
  }

}
