import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormControl } from '@angular/forms';
import { PulseService } from 'src/app/services/pulse/pulse.service';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Pulse } from 'src/app/shared/models/pulse';
import { Ratings } from 'src/app/shared/models/ratings';
import { Router } from '@angular/router';
import { AwsSdkService } from 'src/app/services/aws-sdk/aws-sdk.service';

@Component({
  selector: 'app-pulse-create',
  templateUrl: './pulse-create.component.html',
  styleUrls: ['./pulse-create.component.scss']
})


export class PulseCreateComponent implements OnInit {

  linkColor: any;
  tabs = ['First'];
  selected = new FormControl(0);
  boxs: any = [];
  boxArray: any = [];
  starLable: any;
  reasonLable: any;

  constructor(private cookiesService: CookiesService, private pulseService: PulseService,
    private _snackBar: MatSnackBar, private router: Router, private awsService: AwsSdkService) {

  }

  pulse3: Pulse;
  starLableArr: any = [];
  reasonLableArr: any = [];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  liveEntryMessage = '';
  liveEndMessage = '';
  liveFooterMessage = '';
  liveSubtitle = '';
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  k = 0;
  addTab() {
    this.tabs.push('New');
    this.selected.setValue(this.tabs.length - 1);
    this.starLableArr.push(this.starLable);
    // this.pulse3.ratings[k].push()
    // this.reasonLableArr.push(this.reasonLable);
    this.ratings.push({
      "value": String(this.i),   // 
      "label": this.starLableArr[this.k],
      "subtitle": this.liveSubtitle,
      "reasons": this.boxs  // Change
    });

    this.starLable = '';
    this.reasonLable = '';
    this.boxs = [];
    // this.reasonLable = [];
    this.i = this.i + 1;
    this.k = this.k + 1;
  }

  removeTab() {
    this.tabs.pop();
    this.selected.setValue(this.tabs.length - 1);
  }

  ngOnInit() {

  }

  pulse;
  ratings: any = [];
  orgId;
  createdBy;
  logoUrl;
  entryMessage;
  entryColor;
  footerMessage;
  exitName;
  themeColor;
  feedbackTimeout;
  subtitle;
  i = 1; // rating value
  onConfigAdded(name, url, color, entryMessage, entryColor, footerMessage, exitName, themeColor, feedbackTimeout, subtitle
  ) {
    this.orgId = +this.cookiesService.getCookie('orgId');
    this.createdBy = this.cookiesService.getCookie('createdBy');
    this.createdBy = +this.createdBy;
    // this.logoUrl = url.value;
    this.logoUrl = this.brandLogoUrl;
    this.entryMessage = entryMessage.value;
    this.entryColor = '';
    this.footerMessage = footerMessage.value;
    this.exitName = exitName.value;
    this.themeColor = '';
    this.feedbackTimeout = feedbackTimeout.value;
    this.subtitle = subtitle.value;
    console.log('this.box ', this.boxs);

    this.ratings.push({
      value: String(this.i),   // 
      label: this.starLableArr[this.k],
      // subtitle: "What could have been better?",
      subtitle: this.liveSubtitle,
      reasons: this.boxs  // Change
    });
    console.log('ratings ', this.ratings);
    this.pulse3 = {
      "configName": name.value,
      "logoUrl": this.logoUrl,
      "brandColor": color.value,
      "entryMessage": entryMessage.value,
      "endMessage": exitName.value,
      "ratings": this.ratings,   // two fields are remaining to add
      "createdBy": this.createdBy,  // update
      "orgId": +this.orgId,
      "id": null,
      'timeOut': +feedbackTimeout.value,
      "footerMessage": footerMessage.value,
      "themeColor": this.linkThemeColor,
      "entryColorCode": this.linkEntryColor,
      "ageGroup": [
        {
          'ageGroup': '15-25',
          'orgId': +this.orgId
        },
        {
          'ageGroup': '26-35',
          'orgId': +this.orgId
        },
        {
          'ageGroup': '36-45',
          'orgId': +this.orgId
        },
        {
          'ageGroup': '46-55',
          'orgId': +this.orgId
        },
        {
          'ageGroup': '55 Above',
          'orgId': +this.orgId
        }
      ]
    };
    console.log('pulse', this.pulse3);
    this.addPulseConfiguration(this.pulse3);
  }

  linkColor1;
  setColor(newColor) {
    console.log('value', newColor);
    this.linkColor1 = newColor;
  }

  linkEntryColor: any;
  setEntryColor(newEntryColor) {
    console.log('entry color value', newEntryColor);
    this.linkEntryColor = newEntryColor;
  }

  linkThemeColor: any;
  setThemeColor(newThemeColor) {
    console.log('theme color value', newThemeColor);
    this.linkColor = newThemeColor;
    this.linkThemeColor = newThemeColor;
  }

  setButtonStyle() {
    const styles = {
      'border-color': this.linkColor,
      'color': this.linkColor
    };
    return styles;
  }

  boxArrayType: any = [];
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // console.log('input ', input);
    // console.log('value ', value);

    // Add our fruit
    if ((value).trim()) {
      this.boxs.push(value.trim());
      this.boxArray.push(value.trim());
    }
    this.boxArrayType.push({ value: value.trim() });
    console.log('this boxArrayType', this.boxArrayType);
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(box): void {
    const index = this.boxs.indexOf(box);
    const index1 = this.boxArray.indexOf(box);
    if (index >= 0) {
      this.boxs.splice(index, 1);
      this.boxArray.splice(index1, 1);
    }
  }

  addPulseConfiguration(pulse) {
    console.log('sending pulse data to backend', pulse);
    this.pulseService.addOrUpdatePulseConfig(pulse)
      .subscribe(
        res => {
          console.log(res);
          if (res.statusInfo.statusCode === 200) {
            this._snackBar.open('Configuration added', 'success', {
              duration: 2000,
            });
            this.router.navigate(['/dashboard/pulseConfigList']);
          }
        },
        error => { console.log(error); });
  }

  logoUploadedUrl: any;
  public selectedFiles: FileList;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  @ViewChild('orgLogo', { static: false }) orgLogo: any;
  public onFileChange(event) {
    this.selectedFiles = event.target.files;
    console.log(this.selectedFiles);
    this.upload();
  }
  fileName: any;
  brandLogoUrl = 'https://rain-files.s3.ap-south-1.amazonaws.com/';
  public upload() {
    const file = this.selectedFiles.item(0);
    this.fileName = file.name;
    this.awsService.uploadfile(file);
    this.brandLogoUrl = 'https://rain-files.s3.ap-south-1.amazonaws.com/';
    this.brandLogoUrl = this.brandLogoUrl + file.name;

  }


}
