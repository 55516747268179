import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CountryService } from 'src/app/services/country/country.service';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {
  successMessage: string;
  dataInsertBool = true;
  userCreateForm: FormGroup;
  orgId: any;
  submitted: boolean = false;
  countryControl = new FormControl('', [Validators.required]);

  constructor(private userService: UserService, private cookiesService: CookiesService,
    private snackBar: MatSnackBar, private formBuilder: FormBuilder, private router: Router, private countryService: CountryService) {
    this.orgId = this.cookiesService.getCookie('orgId');
    this.getCountryListByOrgId(this.orgId);
  }

  ngOnInit() {
    this.userCreateForm = this.formBuilder.group({
      userName: ['', Validators.required],
      passWord: ['', Validators.required],
      email: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      countryCode: ['', Validators.required],
      // createdBy: ['', Validators.required],
      isActive: [''],
    });
    console.log('user create form: ', this.userCreateForm);

  }

  get f() { return this.userCreateForm.controls; }

  onSubmit(f) {
    this.submitted = true;
    if (this.userCreateForm.valid) {
      this.addUser()
    }
    return;
  }

  addUser() {
    let data = {
      username: this.userCreateForm.value.userName,
      password: this.userCreateForm.value.passWord,
      emailId: this.userCreateForm.value.email,
      phoneNo: this.userCreateForm.value.phoneNumber,
      countryId: this.countryCode,
      createdBy: +this.cookiesService.getCookie('createdBy'),
      isActive: this.userCreateForm.value.isActive,
      orgId: +this.cookiesService.getCookie('orgId'),
    }
    this.userService.addUser(data)
      .subscribe(
        res => {
          console.log('email data: ', data.emailId);
          console.log('add user api response', res);
          if (res.statusInfo.statusCode === 200) {
            this.dataInsertBool = false;
            this.snackBar.open('new user account is created successfully', 'SUCCESS', {
              duration: 2000
            });
            this.router.navigate(['/dashboard/userList']);
            // this.successMessage = 'sales area added successfully';
          }
        },
        error => { console.log(error); });
  }

  countries: any = [];
  // GET countryList by passing orgId
  getCountryListByOrgId(orgId) {
    this.countryService.getCountryListByParam(orgId)
      .subscribe(
        res => {
          this.countries = res;
          // this.countryData = res;
          console.log('country list', this.countries);
        },
        err => {
          console.log(err);
        }
      );
  }

  countryCode: any;
  onClickCountry(country) {
    console.log('selected country code: ', country.id);
    this.countryCode = country.id;
  }


}
