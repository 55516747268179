import { Component, OnInit } from '@angular/core';
import { CookiesService } from 'src/app/services/cookies/cookies.service';

@Component({
  selector: 'app-user-list-role',
  templateUrl: './user-list-role.component.html',
  styleUrls: ['./user-list-role.component.scss']
})
export class UserListRoleComponent implements OnInit {

  orgId: any;
  roleData: any = [];

  name;
  description;
  id;
  constructor(private cookiesService: CookiesService) {
    this.name = localStorage.getItem('roleName');
    this.id = localStorage.getItem('roleId');
    this.description = localStorage.getItem('description');
    this.orgId = this.cookiesService.getCookie('orgId');
    // this.roleData = this.cookiesService.getCookie('roles');
  }
  disabled = true;
  ngOnInit() {
  }

  onEditClick() {
    this.disabled = false;
  }

  updateChartDetail(name, description) {

  }

}
