import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StoreService } from 'src/app/services/store/store.service';
@Component({
  selector: 'app-store-detail',
  templateUrl: './store-detail.component.html',
  styleUrls: ['./store-detail.component.scss']
})
export class StoreDetailComponent implements OnInit {

  ngOnInit(): void {
    this.updateStoreForm = this.formBuilder.group({
      storeName: ['', Validators.required],
      storeCode: ['', Validators.required],
      displayName: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      street: ['', Validators.required],
      pincode: ['', Validators.required],
      state: ['', Validators.required],
      storePhone: ['', Validators.required],
      storeEmail: ['', Validators.required],
      storeSize: ['', Validators.required],
      storeOpenTime: ['', Validators.required],
      storeCloseTime: ['', Validators.required],
      isActive: ['']
      // orgId: ['', Validators.required],
    });
  }

  get f() { return this.updateStoreForm.controls; }
  submitted: boolean = false;

  onSubmit(f) {
    this.submitted = true;
    if (this.updateStoreForm.valid) {
      this.onUpdateStore()
    }
    return;
  }

  selectedStoreId: any;
  updateStoreForm: FormGroup;
  orgId: any;
  storeId: any;
  constructor(private router: Router, private cookiesService: CookiesService, private storeService: StoreService,
    private snackBar: MatSnackBar, private formBuilder: FormBuilder) {
    this.orgId = this.cookiesService.getCookie('orgId');
    this.storeId = this.cookiesService.getCookie('storeId');
    this.getStoreById(this.cookiesService.getCookie('storeId'));
  }

  store: any;
  getStoreById(id) {
    this.storeService.getStoreByStoreId(id)
      .subscribe(res => {
        this.store = res.responseData;
      },
        error => {
          console.log('error message: ', error);
        }
      );
  }

  onUpdateStore() {
    let StoreSpace = {
      store:
      {
        id: +this.store.id,
        storeName: this.updateStoreForm.value.storeName,
        storeCode: this.updateStoreForm.value.storeCode,
        storeDisplayName: this.updateStoreForm.value.displayName,
        address:
        {
          address: this.updateStoreForm.value.address,
          city: this.updateStoreForm.value.city,
          street: this.updateStoreForm.value.street,
          pincode: +this.updateStoreForm.value.pincode,
          state: this.updateStoreForm.value.state
        },
        storePhone: this.updateStoreForm.value.storePhone,
        storeEmail: this.updateStoreForm.value.storeEmail,
        storeSize: this.updateStoreForm.value.storeSize,
        storeOpenTime: this.updateStoreForm.value.storeOpenTime,
        storeCloseTime: this.updateStoreForm.value.storeCloseTime,
        orgId: +this.cookiesService.getCookie('orgId'),
        isActive: +this.updateStoreForm.value.isActive
      }
    };
    console.log('send store data ', StoreSpace);
    this.storeService.addStore(StoreSpace)
      .subscribe(
        res => {
          console.log('add store response: ', res);
          if (res.statusInfo.statusCode === 200) {
            this.snackBar.open('Store added successfully', 'SUCCESS', { duration: 2000 });
            this.router.navigate(['/dashboard/store']);
          }
        },
        error => { console.log('error message : ', error); });
  }


}
