import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { SpaceService } from 'src/app/services/space/space.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AwsSdkService } from 'src/app/services/aws-sdk/aws-sdk.service';

@Component({
  selector: 'app-space-detail',
  templateUrl: './space-detail.component.html',
  styleUrls: ['./space-detail.component.scss']
})
export class SpaceDetailComponent implements OnInit {

  orgId;
  spaceId;
  updateSpaceForm: FormGroup;
  constructor(private cookiesService: CookiesService, private router: Router, private awsService: AwsSdkService,
    private formBuilder: FormBuilder, private spaceService: SpaceService, private matSnack: MatSnackBar) {
    this.orgId = this.cookiesService.getCookie('orgId');
    this.spaceId = +this.cookiesService.getCookie('spaceId');
    this.getSpaceById(this.spaceId);
  }
  ngOnInit() {
    this.updateSpaceForm = this.formBuilder.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      displayName: ['', Validators.required],
      description: [''],
      // url: [''],
      isActive: ['false'],
    });
  }

  space: any;
  spaceImage: any;
  getSpaceById(id) {
    this.spaceService.getSpaceBySpaceId(id, this.orgId)
      .subscribe(res => {
        this.space = res.responseData[0];
      },
        error => {
          console.log('error message: ', error);
        }
      );
  }

  get f() { return this.updateSpaceForm.controls; }
  submitted: boolean = false;

  onSubmit(f) {
    this.submitted = true;
    if (this.updateSpaceForm.valid) {
      this.onUpdate();
    }
    return;
  }

  brandLogo: any;
  onUpdate() {
    let data = {
      id: +this.space.id,
      name: this.updateSpaceForm.value.name,
      code: this.updateSpaceForm.value.code,
      displayName: this.updateSpaceForm.value.displayName,
      description: this.updateSpaceForm.value.description,
      // url: this.updateSpaceForm.value.url,
      url: this.space.url,
      // createdBy: +this.cookiesService.getCookie('createdBy'),
      isActive: this.updateSpaceForm.value.isActive,
      orgId: +this.cookiesService.getCookie('orgId'),
    };
    console.log('space data: ', data);
    this.spaceService.addSpace(data)
      .subscribe(
        res => {
          if (res.statusInfo.statusCode === 200) {
            this.matSnack.open('Space info has been updated', 'SUCCESS', { duration: 3000 });
            this.router.navigate(['/dashboard/space']);
          }
        },
        error => { console.log('error message: ', error); });
  }

  logoUploadedUrl: any;
  public selectedFiles: FileList;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  @ViewChild('url', { static: false }) url: any;
  public onFileChange(event) {
    this.selectedFiles = event.target.files;
    console.log(this.selectedFiles);
    this.upload();
  }
  fileName: any;
  brandLogoUrl;
  public upload() {
    const file = this.selectedFiles.item(0);
    this.fileName = file.name;
    this.brandLogoUrl = 'https://rain-files.s3.ap-south-1.amazonaws.com/';
    this.awsService.uploadfile(file);
    this.brandLogoUrl = this.brandLogoUrl + file.name;
    this.space.url = this.brandLogoUrl;
  }

}
