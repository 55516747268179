import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { RAIN } from '../../app-constants';
import { environment } from '../../../environments/environment';
import { CookiesService } from '../cookies/cookies.service';
import { ErrorService } from '../error/error.service';
@Injectable({
  providedIn: 'root'
})
export class StoreService {
  ENV: any = environment;
  constructor(private httpClient: HttpClient, private cookiesService: CookiesService, private errorHandler: ErrorService) { }

  // Space-store mapping related URLs
  // private GET_STORE_BY_SALES_AREA_CODE_AND_ORG_ID_URL_ = ''; // update before use
  private GET_SPACE_BY_PARAM_URL = this.ENV.APIEndpoint + RAIN.MASTER + RAIN.STORE + RAIN.APIS.GET_ALL_SPACE;
  private MAP_SPACE_TO_STORE_URL = this.ENV.APIEndpoint + RAIN.MASTER + RAIN.STORE + RAIN.APIS.MAP_SPACE_TO_STORE;
  private UNMAP_SPACE_FROM_STORE_URL = this.ENV.APIEndpoint + RAIN.MASTER + RAIN.STORE + RAIN.APIS.DELETE_SPACE_TO_STORE;
  private GET_SPACE_LIST_BY_STORE_ID_AND_ORG_ID = this.ENV.APIEndpoint + RAIN.MASTER + RAIN.STORE + RAIN.APIS.GET_ALL_SPACE;
  private ADD_STORE_URL = this.ENV.APIEndpoint + RAIN.MASTER + RAIN.STORE + RAIN.APIS.CREATE;
  private GET_STORE_BY_PARAM_URL = this.ENV.APIEndpoint + RAIN.MASTER + RAIN.STORE + RAIN.APIS.GET_ALL_STORE;
  private DELETE_STORE_URL = this.ENV.APIEndpoint + RAIN.MASTER + RAIN.STORE + RAIN.APIS.DELETE_STORE;
  private ADD_STORE_MANAGER = this.ENV.APIEndpoint + RAIN.MASTER + RAIN.STORE + RAIN.APIS.ADD_STORE_MANAGER;
  private UPDATE_STORE = this.ENV.APIEndpoint + RAIN.MASTER + RAIN.STORE + '';
  private GET_STORE_BY_STORE_ID = this.ENV.APIEndpoint + RAIN.MASTER + RAIN.STORE + RAIN.APIS.GET;

  // GET Space list by orgId
  getSpaceListByOrgId(orgId) {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        },
      ),
      params: new HttpParams().set('orgId', orgId)
    }
    return this.httpClient.get<any>(this.GET_SPACE_BY_PARAM_URL, httpOptions)
      .pipe(map(
        res => { return res; },
        error => {
          console.log(error);
        }));
  }

  // GET Store List by OrgId
  getStoreListByParam(orgId) {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: new HttpParams().set('orgId', orgId)
    }
    console.log('orgId checking::', orgId);
    return this.httpClient.get<any>(this.GET_STORE_BY_PARAM_URL, httpOptions)
      .pipe(map(res => { console.log('store ', res); return res; },
        err => { console.log('get store in mapping error: ', err); }
      ));
  }


  addStore(StoreCreate) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      })
    };
    return this.httpClient.post<any>(this.ADD_STORE_URL, StoreCreate, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
    // .pipe(map(data => { console.log('store response data: ', data); return data; }, error => { console.log(error); }));
  }

  // GET Space list by storeId and orgId
  getSpaceListByStoreCodeAndOrgId(storeCode, orgId) {
    let params = new HttpParams();
    params = params.append('orgId', orgId);
    params = params.append('storeId', storeCode);
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: params
    }

    return this.httpClient.get<any>(this.GET_SPACE_LIST_BY_STORE_ID_AND_ORG_ID, httpOptions)
      .pipe(map(
        res => { return res; },
        error => {
          console.log(error);
        }));
  }

  // POST call - mapping Space store
  mapSpaceToStore(storeId, spaces, orgId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      })
    };
    return this.httpClient.post<any>(this.MAP_SPACE_TO_STORE_URL, { storeId, spaces, orgId }, httpOptions)
      .pipe(map(data => { return data; }, err => { console.log(err); }));
  }

  // POST call - unmap space from store
  unmapSpaceFromStore(storeId, spaces, orgId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      })
    };
    return this.httpClient.post<any>(this.UNMAP_SPACE_FROM_STORE_URL, { storeId, spaces, orgId }, httpOptions)
      .pipe(map(
        data => { return data; },
        error => {
          console.log(error);
        }));
  }

  // POST call - delete Store
  deleteStore(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      })
    };
    return this.httpClient.post<any>(this.DELETE_STORE_URL, data, httpOptions)
      .pipe(map(
        data => { return data; },
        error => {
          console.log(error);
        }));
  }

  addSalesAreaManager(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      })
    };
    return this.httpClient.post<any>(this.ADD_STORE_MANAGER, data, httpOptions);
  }

  updateStore(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.cookiesService.getCookie('authToken')
      })
    };
    return this.httpClient.post<any>(this.UPDATE_STORE, data, httpOptions);
  }

  getStoreByStoreId(id) {
    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.cookiesService.getCookie('authToken')
        }
      ),
      params: new HttpParams().set('id', id)
    }
    return this.httpClient.get<any>(this.GET_STORE_BY_STORE_ID, httpOptions);
  }

}
