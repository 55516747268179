import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrganizationService } from 'src/app/services/organization/organization.service';
import { RAIN } from 'src/app/app-constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  username: string;
  password: string;
  invalidLogin = false;
  showError: boolean = false;
  orgId: string;
  submitted = false;
  loader: boolean = false

  constructor(private router: Router,
    private arouter: ActivatedRoute,
    private loginService: AuthenticationService,
    private formBuilder: FormBuilder,
    private _cookiesService: CookiesService, private orgService: OrganizationService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      userName: ['', Validators.required],
      passWord: ['', Validators.required],
    });

    this._cookiesService.clear();
  }

  get f() { return this.loginForm.controls; }

  onSubmit(f) {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.login()
    }
    return;

  }



  login() {
    this.loader = true;
    this.username = this.loginForm.value.userName;
    this.password = this.loginForm.value.passWord;

    (this.loginService.authentication(this.username, this.password).subscribe(
      res => {
        console.log('response of login api call', res);
        this.loader = false;
        this.invalidLogin = false;
        if (res.statusInfo.statusCode === 200) {
          this.orgId = res.responseData.orgId;
          this._cookiesService.putCookie('authToken', res.responseData.authToken)
          this._cookiesService.putCookie('orgId', this.orgId);
          this._cookiesService.putCookie('username', this.username);
          this._cookiesService.putCookie('createdBy', res.responseData.userId);
          // this.getOrgInfo(this.orgId);
          // tslint:disable-next-line: forin
          for (var i in res.responseData.roles) {
            console.log('res.responseData.roles', (res.responseData.roles.length));
            if (res.responseData.roles.length === 0 && res.responseData.authToken) {
              this.router.navigate(['/dashboard']);
            }
            else {
              for (var i in res.responseData.roles) {
                if (res.responseData.roles[i].superAdmin) {
                  this.orgId = res.responseData.orgId;
                  localStorage.setItem('superAdmin', 'true');
                  this._cookiesService.putCookie('superAdminOrgId', this.orgId);
                  this.router.navigate(['/org']);
                }
                else {
                  this.router.navigate(['/dashboard']);
                }
              }
            }
          }
        }
      },
      error => {
        this.loader = false;
        this.invalidLogin = true;
        this.showError = true;
        alert(error);
        // location.reload();
        this.router.navigate(['/login']);
        console.log('error message while login: ', error);
      }
    )
    );
  }

  // landingRoute(role: any) {
  // if (role == 'ROLE_ADMIN') {
  // this.router.navigate(['/org']);
  // }
  // else {
  // this.router.navigate(['/dashboard']);
  // }
  // if (role == 'Organization Aadmin') {
  //   this.router.navigate(['/dashboard']);
  // }
  // }

}
