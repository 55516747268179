import { Component, OnInit } from '@angular/core';
import { RoleService } from 'src/app/services/role/role.service';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-map-role-action',
  templateUrl: './map-role-action.component.html',
  styleUrls: ['./map-role-action.component.scss']
})
export class MapRoleActionComponent implements OnInit {

  constructor(private roleService: RoleService, private cookiesService: CookiesService, private matSnackbar: MatSnackBar) {
    this.getRoleListByOrgId();
    this.getActionList();
  }

  ngOnInit() {
  }
  roleControl = new FormControl('', [Validators.required]);

  roles: any = [];
  getRoleListByOrgId() {
    this.roleService.getRoleListByOrgId(this.cookiesService.getCookie('orgId'))
      .subscribe(
        res => {
          this.roles = res;
          console.log('role list: ', this.roles);
        },
        error => {
          console.log('error message: ', error);
        }
      );
  }

  mappedActions: any = [];
  getMappedActionList(id) {
    this.roleService.getMappedActionList(id).subscribe(
      res => {
        this.mappedActions = res;
      },
      error => {
        console.log('error message: ', error);
      }
    );
  }

  unmappedActions: any = [];
  getUnmappedActionList(id) {
    this.roleService.getUnmappedActionList(id).subscribe(
      res => {
        this.unmappedActions = res;
      },
      error => {
        console.log('error message: ', error);
      }
    );
  }

  actionList: any = [];
  getActionList() {
    this.roleService.getActionList().subscribe(
      res => {
        this.actionList = res;
        console.log('res: ', res);
      },
      error => {
        console.log('error message: ', error);
      }
    );
  }

  mapActionToRole(role_id, action_id) {
    const data =
    {
      'roleId': role_id,
      'actionId': action_id
    }
    this.roleService.mapActionToRole(data).subscribe(
      res => {
        if (res.statusInfo.statusCode === 200) {
          this.matSnackbar.open('mapping operation is successfully completed', 'SUCCESS', { duration: 2000 });
          this.getMappedActionList(this.selectedRoleId);
          this.getUnmappedActionList(this.selectedRoleId);
        }
      },
      error => {
        console.log('error message: ', error);
      }
    );
  }

  unmapActionToRole(role_id, action_id) {
    const data =
    {
      'roleId': role_id,
      'actionId': action_id
    }
    this.roleService.unmapActionToRole(data).subscribe(
      res => {
        if (res.statusInfo.statusCode === 200) {
          this.matSnackbar.open('unmapping operation is successfully completed', 'SUCCESS', { duration: 2000 });
          this.getMappedActionList(this.selectedRoleId);
          this.getUnmappedActionList(this.selectedRoleId);
        }
      },
      error => {
        console.log('error message: ', error);
      }
    );
  }

  selectedRoleId: any;
  onSelectRole(role) {
    console.log('selected role: ', role);
    this.selectedRoleId = role.id;
    this.getMappedActionList(role.id);
    this.getUnmappedActionList(role.id);
  }

  tempObj = [];
  salesArea1 = [];
  tempObj1 = [];
  unmapClick(action_id) {
    console.log(this.selectedRoleId, '  ', action_id)
    this.unmapActionToRole(this.selectedRoleId, action_id);
  }
  mapClick(action_id) {
    console.log(this.selectedRoleId, '  ', action_id)
    this.mapActionToRole(this.selectedRoleId, action_id);
  }

}
